<template>
  <div class="m-2 mt-0">
    <div class="d-flex">
      <label
        for="inputGoTo"
        class="mr-2"
      >Opção de GoToFinal </label>
      <vs-switch
        id="inputGoTo"
        v-model="switchBtn"
        class="mt-n1"
        color="success"
        name="listinputGoToInputSw"
        @change="switchBtnChanged()"
      />
    </div>
    <div
      v-if="switchBtn"
      class="ml-3"
    >
      <div class="ml-3">
        <label for="Pergunta" class="mb-0">Pergunta:</label>
        <select
          id="Pergunta"
          v-model="selectQuestion"
          autocomplete="on"
          name="Pergunta"
          class="c-input mb-2"
          @change="questionChanged()"
        >
          <option
            v-for="question in questionsList"
            :key="question.id"
            :value="question"
          >
            {{ question.id + ': ' + question.text }}
          </option>
        </select>
      </div>
      <!-- Operator -->
      <div class="ml-3">
        <label for="Operator" class="mb-0">Operador:</label>
        <select
          id="Operator"
          v-model="selectOperator"
          name="Operator"
          class="c-input mb-2"
          @change="saveConfig()"
        >
          <option
            v-for="(operator,index) in operatorList"
            :key="index"
            :value="operator.value"
          >
            {{ operator.text }}
          </option>
        </select>
      </div>

      <!-- Answer -->
      <div class="ml-3">
        <div class="w-100">
          <label for="Resposta" class="mb-0">Resposta:</label>
          <select
            id="Resposta"
            v-model="answer"
            autocomplete="on"
            class="c-input mb-2"
            name="Resposta"
            @change="saveConfig()"
          >
            <option
              v-for="(option, index) in answersList"
              :key="index"
              :value="option.text"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GoToOptionsForUpdateQuestion',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    questionsList: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      switchBtn: false,
      localGoToConfig: '',

      selectQuestion: {},
      selectOperator: '=',
      operatorList: [
        {text: '= (igual)', value: '='},
        {text: '! (diferente)', value: '!'}
      ],

      answer: '',
      answersList: [],
    }
  },
  created(){
    this.localGoToConfig = this.value
    this.treatConfigValuesToDisplay()
  },
  methods: {
    treatConfigValuesToDisplay() {
      if (this.localGoToConfig) {
        this.switchBtn = true;
        this.getQuestionFromConfig()
        this.selectOperator = this.localGoToConfig.split(':')[3];
        this.getAnswerFromConfig()
      }
    },
    getQuestionFromConfig() {
      const questionId = this.localGoToConfig.split(':')[2];
      this.selectQuestion = this.questionsList.filter(e => e.id = questionId)[0];
    },
    getAnswerFromConfig() {
      this.answersList = this.selectQuestion.options
      this.answer = this.localGoToConfig.split(':')[4]
    },
    switchBtnChanged() {
      if (!this.switchBtn) {
        this.localGoToConfig = ''
        this.$emit('input', '')
      } else {
        this.saveConfig()
      }
    },
    questionChanged() {
      this.selectOperator = this.operatorList[0].value
      this.answersList = this.selectQuestion.options
      this.answer = this.answersList[0].text
      this.saveConfig()
    },
    saveConfig() {
      if (this.selectQuestion.id && this.selectOperator && this.answer) {
        this.$emit('input', 'goTo:final:' + this.selectQuestion.id + ':' + this.selectOperator + ':' + this.answer)
      } else {
        this.cleanConfig()
      }
    },
    cleanConfig() {
      this.$emit('input', '')
    }
  }
}
</script>
<style scoped>
h4, h5 {
  font-weight: 300;
}

.vs-table--tbody-table .tr-values td {
  padding: 0.5rem;
}

.c-input {
  width: 100%;
  height: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 7px;
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
}
</style>
