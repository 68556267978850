<template>
  <section
    class="col-sm-12 col-md-12"
    @keydown.esc="$emit('close-update')"
  >
    <ul class="m-2 col-sm-12 col list-inline">
      <li class="mb-2">
        Tipo de lista:
      </li>
      <li class="d-block">
        <vs-radio
          v-model="listOrigin"
          class="d-inline-flex ml-2"
          color="success"
          vs-name="listOrigin"
          vs-value="global"
        >
          Lista Global
        </vs-radio>
      </li>
      <li class="d-block">
        <vs-radio
          v-model="listOrigin"
          class="d-inline-flex ml-2"
          color="success"
          vs-name="listOrigin"
          vs-value="proj"
        >
          Lista Própria
        </vs-radio>
      </li>
    </ul>

    <!-- Global Lists Table -->
    <vs-table
      v-show="listOrigin === 'global'"
      stripe
      search
      pagination
      max-items="8"
      class="table table-striped col-sm-12 col-md-12"
      :data="globalLists"
    >
      <template slot="header">
        <h3>
          Listas globais
        </h3>
      </template>
      <template slot-scope="{data}">
        <vs-tr
          v-for="(item,indextr) in data"
          :key="indextr"
        >
          <vs-td
            :data="data[indextr].item"
            class="py-1"
          >
            <vs-radio
              v-model="selectedList"
              class="d-inline-flex"
              color="success"
              vs-name="data[indextr].item"
              :vs-value="data[indextr].item"
            >
              {{ data[indextr].item }}
            </vs-radio>
          </vs-td>
          <vs-td class="py-1">
            <vs-button
              radius
              color="transparent"
              class="text-dark p-0"
              title="Mostrar lista"
              @click="showList(data[indextr].item)"
            >
              <i
                id="btnShowList"
                class="ml-2 fa fa-list"
                aria-hidden="true"
              />
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!--
      Proj Lists Table
        It's pretty much the same that the above table
        But there are two tables separately because the pagination are different and it caused some errors
        to keep it simple, there are two.
    -->
    <vs-table
      v-show="listOrigin === 'proj'"
      stripe
      search
      pagination
      max-items="8"
      class="table table-striped col-sm-12 col-md-12"
      :data="answerLists"
    >
      <template slot="header">
        <h3>
          Listas do projeto
        </h3>
      </template>
      <template slot-scope="{data}">
        <vs-tr
          v-for="(item,indextr) in data"
          :key="indextr"
        >
          <vs-td
            :data="data[indextr].item"
            class="py-1"
          >
            <vs-radio
              v-model="selectedList"
              class="d-inline-flex"
              color="success"
              vs-name="data[indextr].item"
              :vs-value="data[indextr].item"
            >
              {{ data[indextr].item }}
            </vs-radio>
          </vs-td>
          <vs-td class="py-1">
            <vs-button
              radius
              color="transparent"
              class="text-dark p-0"
              title="Mostrar lista"
              @click="showList(data[indextr].item)"
            >
              <i
                id="btnShowList"
                class="ml-2 fa fa-list"
                aria-hidden="true"
              />
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <br>
    <vs-button
      color="success"
      class="m-2 w-100 col-sm-12 col-md-12"
      :disabled="!selectedList"
      @click="confirmList()"
    >
      {{ selectedList ? 'Confirmar Lista' : 'Escolha uma lista' }}
    </vs-button>


    <vs-popup
      class="h-100"
      title="Itens da Lista"
      :active.sync="isShowListOpen"
      style="z-index: 31000"
    >
      <div
        id="listItemsLoading"
        class="vs-con-loading__container"
      />

      <vs-table
        id="listItemsContent"
        stripe
        pagination
        max-items="12"
        class="table table-striped"
        :data="listItemsArray"
      >
        <template slot-scope="{data}">
          <vs-tr
            v-for="(item,indextr) in data"
            :key="indextr"
          >
            <vs-td
              :data="data[indextr]"
              class="py-1"
            >
              {{ data[indextr] }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </section>
</template>

<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js';

/* Services */
import Survey from '@/services/survey';

const ss = new Survey();

export default {
  name: 'FindList',
  data: () => ({
    listOrigin: 'global',
    selectedList: '',
    isShowListOpen: false,
    listItemsArray: [],
  }),
  computed: {
    globalLists() {
      return this.updateListToObjects(store.state.surveyGlobalLists)
    },
    answerLists() {
      return this.updateListToObjects(store.state.surveyAnswerLists)
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    updateListToObjects(list) {
      // transform list of strings in a list of objects, otherwise the search will not work
      let newList = []
      list.forEach(item => {
        let obj = { 'item': item }
        newList.push(obj)
      })
      return newList
    },
    confirmList() {
      if (this.listOrigin === 'global') {
        return this.$emit('select-list', 'g/' + this.selectedList)
      } else {
        return this.$emit('select-list', this.selectedList)
      }
    },
    showList(item) {
      this.openLoadingInDiv()
      var urlItem =  this.listOrigin === 'global' ? 'g/' + item : store.state.surveyStruct.surveyId + '/' + item;

      ss.getSurveyList(urlItem, this.token)
        .then(ret => {
          this.listItemsArray = ret
          this.closeLoadingDiv()
        })
        .catch(err => {
          this.closeLoadingDiv()
          alert('There was a problem on getting the Survey data: ' + err)
        });

      this.isShowListOpen = true
    },
    openLoadingInDiv() {
      document.getElementById('listItemsContent').setAttribute('style', 'display: none')
      document.getElementById('listItemsLoading').setAttribute('style', 'height: 100px')
      this.$vs.loading({
        container: '#listItemsLoading',
        scale: 0.6
      })
    },
    closeLoadingDiv() {
      this.$vs.loading.close('#listItemsLoading > .con-vs-loading')
      document.getElementById('listItemsLoading').removeAttribute('style')
      document.getElementById('listItemsContent').removeAttribute('style')
    },
  },
}
</script>

<style scoped>

</style>
