<template>
  <div @keydown.esc="$emit('close-update')">
    <div class="col-sm-12 col-md-12 col row m-2">
      <div class="m-2">
        <div class="col-sm-12 col-md-1 col">
          <label
            for="txtCode"
            class="font-weight-bold"
          >
            Código
          </label>

          <vs-input
            id="txtCode"
            v-model="chapLocal.code"
            name="txtCode"
            class="inputx"
            placeholder="automático"
            disabled
          />
        </div>
      </div>

      <div class="m-2 col-sm-12 col-md-5 col">
        <label
          for="txtName"
          class="font-weight-bold"
        >
          Nome
        </label>

        <vs-input
          id="txtName"
          v-model="chapLocal.name"
          name="txtName"
          class="inputx w-100"
        />
      </div>

      <div class="m-2 col-sm-12 col-md-11 col">
        <div>
          <label
            for="txtTitle"
            class="font-weight-bold"
          >
            Título
          </label>

          <vs-input
            id="txtTitle"
            v-model="chapLocal.title"
            class="inputx"
            name="txtTitle"
            style="width:100%"
          />
        </div>
      </div>

      <div class="m-2 col-sm-12 col-md-11 col">
        <div>
          <label
            for="txtSubTitle"
            class="font-weight-bold"
          >
            Subtítulo
          </label>

          <vs-input
            id="txtSubTitle"
            v-model="chapLocal.subTitle"
            class="inputx"
            name="txtSubTitle"
            style="width:100%"
          />
        </div>
      </div>

      <div class="m-2 col-sm-12 col-md-11 col">
        <label
          for="txtIntroMessage"
          class="font-weight-bold"
        >
          Mensagem de Intro
        </label>

        <vs-textarea
          id="txtIntroMessage"
          v-model="chapLocal.introMessage"
          rows="3"
          class="inputx"
          name="txtIntroMessage"
        />
      </div>
    </div>

    <div
      v-if="checkItemAccess(['Editar Projetos Digitais'])"
      class="m-2 col-sm-12 col-md-12"
    >
      <div class="m-2 col-sm-12 col-md-11 col">
        <div>
          <label
            for="txtEndLabel"
            class="font-weight-bold"
          >
            Legenda Final
          </label>

          <vs-input
            id="txtEndLabel"
            v-model="chapLocal.endLabel"
            class="inputx"
            name="txtEndLabel"
            style="width:100%"
          />
        </div>
      </div>

      <div class="m-2 col-sm-12 col-md-11 col">
        <div>
          <label
            for="txtEndTitle"
            class="font-weight-bold"
          >
            Título Final
          </label>

          <vs-input
            id="txtEndTitle"
            v-model="chapLocal.endTitle"
            class="inputx"
            name="txtEndTitle"
            style="width:100%"
          />
        </div>
      </div>

      <div class="m-2 col-sm-12 col-md-11 col mt-4">
        <div class="col-sm-12 col-md-12 col">
          <label
            for="txtEndMessage"
            class="font-weight-bold"
          >
            Mensagem Final
          </label>

          <vs-textarea
            id="txtEndMessage"
            v-model="chapLocal.endMessage"
            rows="3"
            class="inputx"
            name="txtEndMessage"
          />
        </div>
      </div>
    </div>

    <div class="m-2 col-sm-12 col-md-12">
      <div class="m-2 w-100 col-sm-12 col-md-11 col">
        <vs-button
          color="success"
          class="btn-block"
          :disabled="!chapLocal.name"
          @click="saveChapter"
        >
          {{ !chapLocal.name ? 'Insira um nome ao capítulo' : 'Salvar' }}
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'UpdateChapter',
  props: {
    indexChap: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    chapLocal: {},
  }),
  created() {
    // clone the object or create a fresh new one
    if (this.action === 'edit') {
      this.chapLocal = JSON.parse(JSON.stringify(this.$store.state.surveyStruct.chapters[this.indexChap]));
    } else {
      this.chapLocal = this.createFreshChapObject();
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById('txtName').focus();
    })
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true
      else {
        let resp = false

        access.forEach((i) => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true
          }
        })

        return resp
      }
    },
    generateID() {
      // get the last requirement code
      let lastChapID = 0;

      const chapterLength = this.$store.state.surveyStruct.chapters.length;

      if (chapterLength > 0) lastChapID = this.$store.state.surveyStruct.chapters[chapterLength-1].id;

      return lastChapID + 1;
    },
    createFreshChapObject() {
      const surveyId = 0;

      const id = this.generateID();

      const code = this.generateID().toString();

      return {
        id,
        surveyId,
        code,
        endLabel: '',
        endMessage: '',
        endTitle: '',
        introMessage: '',
        name: '',
        subTitle: '',
        title: '',
        endSubTitle: null,
        factor: 0,
        fullFillment: 0,
        newFullFillment: 0,
        newScore: 0,
        reqsFactorSum: 0,
        requirements: [],
        score: 0,
        subChapters: null,
      };
    },
    saveChapter() {
      let chapters = [...this.$store.state.surveyStruct.chapters];

      this.action === 'edit' ? chapters[this.indexChap] = this.chapLocal : chapters.push(this.chapLocal);

      this.$store.dispatch('updateChapters', chapters);

      this.$store.dispatch('saveSurveyStruct')
        .then(() => {
          this.$vs.notify({
            title: 'Salvo',
            text: 'Questionário salvo',
            color: 'success',
            icon: 'check_box'
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Erro ao salvar',
            text: `Tente salvar manualmente: (${err})`,
            color: 'danger',
            icon: 'warning'
          });
        });

      return this.$emit('close-update');
    },
  }
}
</script>
