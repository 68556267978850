<template>
  <div @keydown.esc="$emit('close-update')">
    <form-wizard
      step-size="xs"
      shape="circle"
      title="Criação de Galeria de Imagens"
      subtitle="Siga os passos abaixo para criar sua lista"
      color="rgba(var(--vs-primary), 1)"
      error-color="#e74c3c"
      @on-complete="saveList"
      @on-loading="setLoading"
      @on-error="handleErrorMessage"
    >
      <div
        v-if="loadingWizard"
        class="loader"
      />

      <!-- Tab 1 : Seleção -->
      <tab-content
        title="Seleção de arquivos"
        :before-change="validateAsyncFiles"
        icon="ti-upload"
      >
        <vs-card>
          <h3 class="card-title d-flex">
            Upload de arquivos
          </h3>
          <p class="card-subtitle">
            Faça o upload dos arquivos que deverão ser exibidos na pesquisa e clique em "próximo".
          </p>
          <div v-if="wizardErrorMsg">
            <vs-alert
              title="Por favor, verifique:"
              active="true"
              color="rgb(255, 130, 0)"
            >
              {{ wizardErrorMsg }}
            </vs-alert>
          </div>

          <!-- Lista de arquivos -->
          <div class="mb-3">
            <div
              v-if="localListItemsArray.length"
              class="con-upload w-100"
            >
              <div class="con-img-upload">
                <div
                  v-for="(item, index) in localListItemsArray"
                  :key="index"
                  class="img-upload"
                >
                  <div
                    class="item d-flex con-input-upload c-card-final"
                    style="margin: 0px"
                  >
                    <button
                      type="button"
                      class="btn-x-file"
                      @click="removeItem(item, index)"
                    >
                      <i
                        translate="translate"
                        class="material-icons notranslate"
                      > clear </i>
                    </button>
                    <button
                      class="btn-upload-file on-progress ready-progress"
                      style="height: 100%;"
                    >
                      <i
                        translate="translate"
                        class="material-icons notranslate"
                      > cloud_done </i><span>100 %</span>
                    </button>
                    <img
                      style="max-height: 60%;"
                      :src="item.ilustration"
                      :alt="item.text"
                    >
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="">
            <hr>
            <div class="d-flex align-items-center">
              <div class="mb-3">
                <input
                  id="file"
                  ref="file"
                  class="file-input"
                  type="file"
                  @change="uploadFile"
                >

                <vs-button icon="file_upload">
                  <label
                    class="m-0 cursor-pointer"
                    for="file"
                  >
                    Procurar...
                  </label>
                </vs-button>
              </div>

              <p class="mb-0 ml-3">
                {{ fileName ? fileName : 'Selecione um arquivo.' }}
              </p>
            </div>

            <h4><span class="c-separator"></span> Ou <span class="c-separator"></span></h4>
            <!-- Input de URLs -->
            <vs-input
              class="w-50 my-3"
              label="Cole uma URL abaixo e pressione a tecla Enter:"
              @keyup.enter="addItem($event)"
            />
          </div>
        </vs-card>
      </tab-content>

      <!-- Tab 2 : Edição das informações -->
      <tab-content
        title="Edição das informações"
        :before-change="insertValueOnList"
        icon="ti-settings"
      >
        <vs-card>
          <h3 class="card-title">
            Edite a lista e seus itens:
          </h3>
          <div class="d-flex col-sm-12 col-md-12">
            <label
              class="pr-2"
              for="swImageZoom"
            >Zoom ao clicar na imagem</label>
            <vs-switch
              v-model="ilustrationConfig.zoom"
              name="swImageZoom"
              color="success"
            />
          </div>
          <div class="col-sm-12 col-md-12 col">
            <label for="aspectRatio">Formato da imagem:</label>
            <select
              id="aspectRatio"
              v-model="ilustrationConfig.aspectRatio"
              autocomplete="on"
              tabindex="0"
              name="aspectRatio"
              class="ml-2 px-2 c-input"
            >
              <option value="1/1">
                Retrato (1/1)
              </option>
              <option value="16/9">
                Retrato (16/9)
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-12 col">
            <label for="objectPosition">Referência de Posição da Imagem:</label>
            <select
              id="objectPosition"
              v-model="ilustrationConfig.objectPosition"
              autocomplete="on"
              tabindex="0"
              name="objectPosition"
              class="ml-2 px-2 c-input"
            >
              <option value="center">
                Centralizado
              </option>
              <option value="top">
                Topo
              </option>
              <option value="bottom">
                Fundo
              </option>
              <option value="left">
                À esquerda
              </option>
              <option value="right">
                À direita
              </option>
            </select>
          </div>

          <div v-if="wizardErrorMsg">
            <vs-alert
              title="Por favor, verifique:"
              active="true"
              color="rgb(255, 130, 0)"
            >
              {{ wizardErrorMsg }}
            </vs-alert>
          </div>

          <label>Renomeie e ordene os itens carregados:</label>
          <div class="con-upload mt-n2">
            <div class="con-img-upload">
              <div
                v-for="(item, index) in localListItemsArray"
                :key="index"
                class="item d-flex con-input-upload"
                style="cursor: grab"
                draggable
                @mouseover="showOptions = index"
                @mouseleave="showOptions = false"
                @dragstart="pickupItem($event, index)"
                @drop="moveItem($event, index)"
                @dragover.prevent
                @dragenter.prevent
              >
                <input
                  type="text"
                  class="input-item"
                  :value="item.text"
                  @keyup="updateItem($event, index)"
                >
                <img
                  :style="{maxHeight: dynamicImgHeight, aspectRatio: ilustrationConfig.aspectRatio, objectFit: 'cover', objectPosition: ilustrationConfig.objectPosition}"
                  :src="item.ilustration"
                  :alt="item.text"
                >
              </div>
            </div>
          </div>
        </vs-card>
      </tab-content>

      <!-- Tab 3 : Revisão final -->
      <tab-content
        title="Revisão final"
        :before-change="joinListIlustrationArray"
        icon="ti-check"
      >
        <vs-card>
          <h3 class="card-title">
            Revise sua lista
          </h3>
          <p class="card-subtitle">
            Verifique se está tudo certo e finalize sua nova lista.
          </p>
          <div class="con-upload mt-n2">
            <div class="con-img-upload">
              <div
                v-for="(item, index) in localListItemsArray"
                :key="index"
                class="item d-flex con-input-upload c-card-final"
              >
                <p
                  class="input-item text-success"
                  style="cursor:default;"
                >
                  {{ item.text }}
                </p>
                <img
                  :src="item.ilustration"
                  :style="{maxHeight: dynamicImgHeight, maxWidth: '100%', aspectRatio: ilustrationConfig.aspectRatio, objectFit: 'cover', objectPosition: ilustrationConfig.objectPosition}"
                  :alt="item.text"
                >
              </div>
            </div>
          </div>
        </vs-card>
      </tab-content>


      <!-- wizard nav buttons -->
      <vs-button
        slot="prev"
        style="width: 100px"
        color="primary"
      >
        Voltar
      </vs-button>
      <vs-button
        slot="next"
        style="width: 100px"
        color="primary"
      >
        Avançar
      </vs-button>
      <vs-button
        slot="finish"
        style="width: 100px"
        color="success"
      >
        Concluir
      </vs-button>
    </form-wizard>
  </div>
</template>

<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js';
import axios from 'axios';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

export default {
  name: 'UpdateGalleryList',
  components: { FormWizard, TabContent },
  props: {
    action: {},
    listItemsArray: Array,
  },
  data: () => ({
    showOptions: false,
    localListItemsArray: [],

    loadingWizard: false,
    wizardErrorMsg: null,
    wizardCount: 0,

    swImageZoom: false,
    ilustrationConfig: {
      zoom: false,
      aspectRatio: '1/1',
      objectPosition: 'center'
    },

    Images: {},
    fileName: ''
  }),
  computed: {
    surveyID() {
      return store.state.surveyStruct.surveyId
    },
    dynamicImgHeight() {
      return this.ilustrationConfig.aspectRatio === '1/1' ? '67%' : '53%'
    }
  },
  created() {
    // if is editing, clone the question object
    if (this.action === 'edit') {
      this.localListItemsArray = [...this.listItemsArray]
      this.localListItemsArray.forEach(el => {
        let array = el.ilustration.split('&')
        el.ilustrationConfig = {}
        array.forEach(elItem => {
          el.ilustrationConfig[elItem.split('=')[0]] = elItem.split('=')[1]
        })
        el.ilustration = el.ilustrationConfig.url
        el.ilustrationConfig.zoom === 'false' ? this.ilustrationConfig.zoom = false : this.ilustrationConfig.zoom = true
        this.ilustrationConfig.aspectRatio = el.ilustrationConfig.aspectRatio
        this.ilustrationConfig.objectPosition = el.ilustrationConfig.objectPosition
        delete el.ilustrationConfig
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value
    },
    handleErrorMessage: function(errorMsg){
      this.wizardErrorMsg = errorMsg
    },
    validateAsyncFiles() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            resolve(true)
          }
        }, 500)
      })
    },
    insertValueOnList() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            this.localListItemsArray.forEach((el, index) => {
              el.value = `${index}`
              //create illustration params
              let array = []
              array.push(`url=${el.ilustration}`)
              array.push(`zoom=${this.ilustrationConfig.zoom}`)
              array.push(`aspectRatio=${this.ilustrationConfig.aspectRatio}`)
              array.push(`objectPosition=${this.ilustrationConfig.objectPosition}`)
              el.ilustrationArray = array
            })
            resolve(true)
          }
        }, 500)
      })
    },
    joinListIlustrationArray() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            this.localListItemsArray.forEach((el) => {
              el.ilustration = el.ilustrationArray.join('&')
              delete el.ilustrationArray
            })
            resolve(true)
          }
        }, 500)
      })
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name
      this.submitFile()
    },
    clearFile() {
      this.Images = {};
      this.fileName = '';
    },
    submitFile() {
      const formData = new FormData();
      formData.append('file', this.Images);
      const headers = { 'Content-Type': 'multipart/form-data' };
      axios.post(`https://dashapi.fanbase.com.br/api/Files/${store.state.ownerID}/3`, formData, { headers }).then((res) => {
        let name
        let src
        if (res.data.header.codigo === 1) {
          name = this.$refs.file.files[0].name ? this.$refs.file.files[0].name.split('.')[0] : 'Uploaded image'
          src = res.data.data.url ? res.data.data.url : 'Src'
          this.$vs.notify({
            color: 'success',
            title: 'Sucesso de upload',
            text: 'Carregamento de arquivos realizado.'
          })
          this.localListItemsArray.push({
            text: name,
            ilustration: src
          })
          this.clearFile()
        } else if (res.data.header.codigo === 0 && res.data.header.msg.startsWith('Arquivo já existente')) {
          name = this.$refs.file.files[0].name ? this.$refs.file.files[0].name.split('.')[0] : 'Uploaded image'
          src = `https://config.fanbase.com.br/user/fanbase/${this.$store.state.ownerID}/files/${this.$refs.file.files[0].name}`
          this.$vs.notify({
            color: 'success',
            title: 'Arquivo encontrado',
            text: `${res.data.header.msg}`
          })
          this.localListItemsArray.push({
            text: name,
            ilustration: src
          })
          this.clearFile()
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'Erro de upload',
            text: `${res.data.header.msg}`
          })
          this.$refs.file.files[0] = 0
          this.clearFile()
        }
      });
    },
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('item-index', itemIndex)
    },
    moveItem(e, toIndex) {
      const fromIndex = e.dataTransfer.getData('item-index')
      const itemToMove = this.localListItemsArray.splice(fromIndex, 1)[0]
      this.localListItemsArray.splice(toIndex, 0, itemToMove)
    },
    removeItem(item, index) {
      this.localListItemsArray.splice(index, 1)
    },
    addItem(e) {
      this.localListItemsArray.push({
        text: 'Inserted link',
        ilustration: e.target.value
      })
      e.target.value = ''
    },
    updateItem(e, index) {
      this.localListItemsArray[index].text = e.target.value
    },
    sortList() {
      this.localListItemsArray.sort((a, b) => {
        return a.text.localeCompare(b.text)
      })
    },
    saveList() {
      this.$emit('save-update', this.localListItemsArray)
    },
  },
}
</script>

<style scoped>
.input-item {
  background-color: transparent;
  text-align: center;
  padding: 3px 0.5rem;
  width: 200px;
  height: auto;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.1);
  position: absolute;
  top: 5px;
  opacity: 1;
  cursor: text;
}

.input-url {
  width: 600px;
  text-overflow: ellipsis;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 8px;
  padding: 3px;
}

.c-card-final {
  border: 1px solid rgba(0,0,0,.1);
  background-color: rgba(var(--vs-success),.15);
}

.c-upload >>> .con-input-upload {
  height: 100px;
}
.c-upload {
  width: auto
}
.c-input {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
}

.file-input {
  display: none;
}
.c-separator {
  background-color: #1f2535;
  height: 1px;
  width: 32px;
  display: inline-block;
  margin-bottom: 6px;
}

</style>
