<template>
  <vs-collapse :type="type">
    <div
      id="chapterTitle"
      class="mb-3"
    >
      <h4>Capítulos</h4>
    </div>

    <vs-collapse-item
      v-for="(chap, indextr) in surveyStruct.chapters"
      :key="indextr"
      class="p-0"
    >
      <div
        slot="header"
        class="d-flex"
        :draggable="checkItemAccess(['Editar Projetos Digitais'])"
        @mouseover="showItemOptions = indextr"
        @mouseleave="showItemOptions = false"
        @dragstart="pickupItem($event, indextr)"
        @drop="moveItem($event, indextr)"
        @dragover.prevent
        @dragenter.prevent
      >
        <div>
          <label class="mb-1 p-0 font-weight-bold font-medium text-uppercase cursor-pointer">
            {{ chap.code }} {{ chap.name ? ` - ` + chap.name : `` }} {{ chap.title ? ` - ` + chap.title : `` }}
          </label>
        </div>

        <!-- Item Options -->
        <transition name="fadeOptions">
          <div
            v-if="showItemOptions === indextr && checkItemAccess(['Editar Projetos Digitais', 'Votacoes'])"
            class="ml-3 mt-n1"
          >
            <div class="d-flex position-absolute mt-n1">
              <vs-button
                radius
                color="transparent"
                class="text-dark p-0 "
                title="Editar Capítulo"
                @click.stop="openUpdate(indextr, 'edit')"
              >
                <i
                  class="fas fa-edit m-2"
                  aria-hidden="true"
                />
              </vs-button>

              <vs-button
                v-if="checkItemAccess(['Editar Projetos Digitais'])"
                radius
                color="transparent"
                class="text-dark p-0"
                title="Excluir Capítulo"
                @click.stop="confirmDelete(indextr)"
              >
                <i
                  class="fas fa-trash m-2"
                  aria-hidden="true"
                />
              </vs-button>
            </div>
          </div>
        </transition>
      </div>

      <RequirTable
        :index-chap="indextr"
        :reqtable="chap.requirements"
        :survey-id="surveyId"
      />
    </vs-collapse-item>

    <p
      v-if="checkItemAccess(['Editar Projetos Digitais'])"
      id="btnNewChapter"
      class="mb-0 p-0 c-btn-add"
      title="Adicionar Novo Capítulo"
      @click="openUpdate(0, 'add')"
    >
      <i
        class="fa fa-plus"
        aria-hidden="true"
      />

      Novo Capítulo
    </p>

    <div
      id="mainChapterLoading"
      class="vs-con-loading__container"
    />

    <vs-popup
      :active.sync="isUpdateOpen"
      title="Configuração de capítulo"
    >
      <div slot="header">
        Configuração Capítulo

        <i class="mr-3 mdi mdi-content-paste" />
      </div>

      <UpdateChapter
        v-if="isUpdateOpen"
        :action="chapterAction"
        :index-chap="indexChap"
        @close-update="closeUpdate"
      />
    </vs-popup>
  </vs-collapse>
</template>

<script>
// Components
import RequirTable from './RequirTable';
import UpdateChapter from './UpdateChapter';

export default {
  name: 'SurvDetail',
  components: {
    RequirTable,
    UpdateChapter,
  },
  props: {
    surveyId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    type: 'default',
    indexChap: 0,
    chapterAction: null,
    isUpdateOpen: false,
    showItemOptions: '',
  }),
  computed: {
    surveyStruct() {
      return this.$store.state.surveyStruct;
    },
  },
  created() {
    this.$store.dispatch('cleanSurveyStruct');

    this.$store.dispatch('getSurvey', this.surveyId)
      .catch(() => alert('Houve um problema buscando os dados do survey.'))
      .finally(() => this.closeLoadingDiv());

    this.$store.dispatch('getQuestionTypes');
    this.$store.dispatch('getSurveyGlobalQuestions');
    this.$store.dispatch('getSurveyAnswerLists', this.surveyId);
    this.$store.dispatch('getSurveyGlobalLists');
  },
  mounted() {
    this.openLoadingInDiv();
  },
  beforeDestroy() {
    this.$store.dispatch('cleanSurveyStruct');
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true

      let resp = false
      access.forEach(i => {
        if (this.$store.state.loginData.accessValues.includes(i)) {
          resp = true
        }
      })
      return resp
    },
    saveSurvey() {
      this.$store.dispatch('saveSurveyStruct')
        .then(() => {
          this.$vs.notify({
            title: 'Salvo',
            text: 'Questionário salvo',
            color: 'success',
            icon: 'check_box'
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Erro ao salvar',
            text: `Tente salvar manualmente: (${err})`,
            color: 'danger',
            icon: 'warning',
          });
        });
    },
    openLoadingInDiv() {
      document.getElementById('chapterTitle').setAttribute('style', 'display: none');

      if (this.checkItemAccess(['Editar Projetos Digitais'])) {
        document.getElementById('btnNewChapter').setAttribute('style', 'display: none');
      }

      document.getElementById('mainChapterLoading').setAttribute('style', 'height: 100px');

      this.$vs.loading({
        container: '#mainChapterLoading',
        scale: 0.6,
      });
    },
    closeLoadingDiv() {
      document.getElementById('chapterTitle').setAttribute('style', 'display: block');

      if (this.checkItemAccess(['Editar Projetos Digitais'])) {
        document.getElementById('btnNewChapter').setAttribute('style', 'display: block');
      }

      this.$vs.loading.close('#mainChapterLoading > .con-vs-loading');

      document.getElementById('mainChapterLoading').removeAttribute('style');
    },
    openUpdate(index, action) {
      this.indexChap = index;
      this.chapterAction = action;
      this.isUpdateOpen = true;
    },
    closeUpdate() {
      this.chapterAction = '';
      this.isUpdateOpen = false;
    },
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'move';

      e.dataTransfer.setData('chap-index', itemIndex);
      e.dataTransfer.setData('type', 'chapter');
    },
    moveItem(e, toIndexChapter) {
      if (e.dataTransfer.getData('type') === 'chapter') {
        this.moveChapter(e, toIndexChapter);
      } else if (e.dataTransfer.getData('type') === 'requirement') {
        this.moveRequirement(e, toIndexChapter);
      } else if (e.dataTransfer.getData('type') === 'question') {
        this.$vs.notify({
          color: 'warning',
          title: 'Ação não realizada',
          text: 'Uma questão só pode ser movida para dentro um requisito'
        });
      }

      this.saveSurvey();
    },
    moveChapter(e, toIndexChapter) {
      const fromIndexChapter = parseInt(e.dataTransfer.getData('chap-index'));

      this.$store.dispatch('moveChapter', {
        fromIndexChapter,
        toIndexChapter
      });
    },
    moveRequirement(e, toIndexChapter) {
      const fromIndexRequir = parseInt(e.dataTransfer.getData('requir-index'));
      const fromIndexChap = parseInt(e.dataTransfer.getData('chap-index'));
      const toIndexRequir = parseInt(this.$store.state.surveyStruct.chapters[toIndexChapter].requirements.length);

      this.$store.dispatch('moveRequirement', {
        fromIndexRequir,
        toIndexRequir,
        fromIndexChap,
        toIndexChap: toIndexChapter
      });
    },
    // delete alert
    confirmDelete(index){
      // store indexChap locally because the deleteAccept() method can't receive arguments
      this.indexChap = index;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: `Apagar o capítulo '${this.surveyStruct.chapters[index].name}'? `,
        accept: this.deleteAccept,
      });
    },
    deleteAccept(){
      this.$store
        .dispatch('deleteChapter', this.indexChap)
        .then(() => this.saveSurvey());

      this.$vs.notify({
        color: 'success',
        title: 'Capítulo apagado',
        text: 'O capítulo selecionado foi apagado com sucesso'
      });
    },
  },
};
</script>

<style lang="css">
.vs-con-input-label {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 150px;
}

.open-item > .vs-collapse-item--content {
  max-height: none !important;
}

.fadeOptions-enter-active, .fadeOptions-leave-active {
  transition: opacity .3s;
}

/* .fade-leave-active below version 2.1.8 */
.fadeOptions-enter, .fadeOptions-leave-to {
  opacity: 0;
}

.c-btn-add {
  color: rgba(43, 43, 43, 0.5);
  display: inline-block;
}

.c-btn-add:hover {
  color: rgba(43, 43, 43, 0.8);
  cursor: pointer;
}
</style>
