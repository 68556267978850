<template>
  <div
    class="requir-layout"
    style="cursor:default;"
  >
    <vs-table
      stripe
      :data="reqtable"
      class="mx-1 pb-3"
      :class="{ 'c-cursor-default' : !checkItemAccess(['Editar Projetos Digitais'])}"
    >
      <template slot="header">
        <h4 class="mt-4 mx-3">
          Perguntas por telas <span
            v-if="checkItemAccess(['Dev'])"
            class="font-weight-normal"
          >(requirements)</span>
        </h4>
      </template>
      <template slot-scope="{ data }">
        <vs-tr
          v-for="(tr, indextr) in data"
          :key="indextr"
        >
          <div
            class="d-flex"
            :class="{'ml-4': checkSkip(tr.config)}"
            :draggable="checkItemAccess(['Editar Projetos Digitais'])"
            @mouseover="showItemOptions = indextr"
            @mouseleave="showItemOptions = false"
            @dragstart="pickupItem($event, indextr)"
            @drop="moveItem($event, indextr)"
            @dragover.prevent
            @dragenter.prevent
          >
            <vs-td
              :data="tr.text"
              class="pb-2 pt-4"
            >
              <span
                v-if="tr.config"
                class="mb-0"
              >
                <i
                  v-if="checkSkip(tr.config)"
                  class="mt-2 mr-2 fa fa-level-down-alt fa-2x float-left"
                />
                <span
                  v-if="translateConfig(tr.config).skip.length"
                  class="c-text-sm"
                >
                  {{ translateConfig(tr.config).skip[0]
                    .replace('skip', 'Pula se: Resposta da questão ')
                    + translateConfig(tr.config).skip[1]
                    + translateConfig(tr.config).skip[2]
                      .replace('!&', ' diferente de ')
                      .replace('!', ' diferente de ')
                      .replace('=|', ' igual a ')
                      .replace('=', ' igual a ')
                    + `"` + translateConfig(tr.config).skip[3].replace('/', '" / "') + `"`
                  }}
                </span>
              </span>
              <p class="m-0">
                <strong style="text-transform: uppercase">{{ `${tr.id}: ${tr.text}` }}</strong>
                <span
                  v-if="tr.config"
                  class="c-text-sm"
                >
                  {{ translateConfig(tr.config).btnok ? `(` + translateConfig(tr.config).btnok + `)` : '' }}
                </span>
                <br>
              </p>
            </vs-td>
            <vs-td
              class="mr-0 pl-0 pb-2 pt-4"
              style="width: 7rem"
            >
              <transition name="fadeOptions">
                <div v-if="showItemOptions === indextr && checkItemAccess(['Editar Projetos Digitais', 'Votacoes'])">
                  <vs-button
                    radius
                    color="transparent"
                    class="text-dark"
                    title="Configurar requisito"
                    @click.stop="openUpdate(indextr, 'edit')"
                  >
                    <i
                      class="fas fa-edit"
                      aria-hidden="true"
                    />
                  </vs-button>
                  <vs-button
                    v-if="checkItemAccess(['Editar Projetos Digitais'])"
                    radius
                    color="transparent"
                    class="text-dark"
                    title="Excluir requisito"
                    @click.stop="confirmDelete(indextr)"
                  >
                    <i
                      class="fas fa-trash ml-2"
                      aria-hidden="true"
                    />
                  </vs-button>
                  <vs-button
                    v-if="checkItemAccess(['Editar Projetos Digitais'])"
                    radius
                    color="transparent"
                    class="text-dark"
                    title="Adicionar abaixo"
                    @click.stop="openUpdate(indextr, 'add')"
                  >
                    <i
                      class="fas fa-plus ml-2"
                      aria-hidden="true"
                    />
                  </vs-button>
                </div>
              </transition>
            </vs-td>
          </div>
          <div>
            <QuestTable
              :questions="data[indextr].questions"
              :index-requir="indextr"
              :index-chap="indexChap"
              :survey-id="surveyId"
              :class="{'pl-5': checkSkip(tr.config)}"
              class="pl-4"
            />
          </div>
        </vs-tr>
        <vs-tr v-if="checkItemAccess(['Editar Projetos Digitais'])">
          <vs-button
            radius
            color="transparent"
            class="mt-3 pl-3 text-dark c-text-sm"
            title="Adicionar requisito"
            @click.stop="openUpdate(reqtable.length-1, 'add')"
          >
            <i
              class="fas fa-plus fa-xs"
              aria-hidden="true"
            />
            Novo requisito
          </vs-button>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      title="Configuração de requisito"
      :active.sync="isUpdateOpen"
    >
      <UpdateRequir
        v-if="isUpdateOpen"
        :action="requirAction"
        :index-chap="indexChap"
        :index-requir="indexRequir"
        @close-update="closeUpdate"
      />
    </vs-popup>
  </div>
</template>
<script>

import UpdateRequir from '@/views/dashboards/dashboard-components/survey-structure/UpdateRequir';
import QuestTable from '@/views/dashboards/dashboard-components/survey-structure/QuestTable';

export default {
  name: 'RequirTable',
  components: {
    UpdateRequir,
    QuestTable,
  },
  props: {
    surveyId: Number,
    indexChap: Number,
    reqtable: Array,
  },
  data: () => ({
    title: 'RequirTable',
    indexRequir: 0,
    isUpdateOpen: false,
    requirAction: null,
    showItemOptions: '',
  }),
  methods: {
    translateConfig(config) {
      let obj = {
        btnok: '',
        skip: []
      }
      let array = config.split(';')
      array.forEach(item => {
        if (item.includes('btnok')) obj.btnok = item.replace('btnok:', 'Texto do botão: ')
        if (item.includes('skip')) obj.skip = item.split(',')
      })
      return obj
    },
    checkItemAccess(access) {
      if (!access) return true
      else {
        let resp = false
        access.forEach(i => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true
          }
        })
        return resp
      }
    },
    checkSkip(config) {
      if (typeof config === 'string') {
        return config.includes('skip');
      } else return false
    },
    openUpdate(index, action) {
      this.indexRequir = index
      this.requirAction = action
      this.isUpdateOpen = true
    },
    closeUpdate() {
      this.requirAction = ''
      this.isUpdateOpen = false
    },
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('requir-index', itemIndex)
      e.dataTransfer.setData('chap-index', this.indexChap)
      e.dataTransfer.setData('type', 'requirement')
    },
    moveItem(e, toIndexRequir) {
      if (e.dataTransfer.getData('type') === 'chapter') {
        this.moveChapter(e)
      } else if (e.dataTransfer.getData('type') === 'requirement') {
        this.moveRequirement(e, toIndexRequir)
      } else if (e.dataTransfer.getData('type') === 'question') {
        this.moveQuestion(e, toIndexRequir)
      }
      this.saveSurvey();
    },
    saveSurvey() {
      this.$store.dispatch('saveSurveyStruct').then(() => {
        this.$vs.notify({title: 'Salvo', text: 'Questionário salvo', color: 'success', icon: 'check_box'})
      }).catch((err) => {
        this.$vs.notify({title: 'Erro ao salvar', text: `Tente salvar manualmente: (${err})`, color: 'danger', icon: 'warning'})
      })
    },
    moveChapter(e) {
      const fromIndexChapter = parseInt(e.dataTransfer.getData('chap-index'))
      this.$store.dispatch('moveChapter', {
        fromIndexChapter,
        toIndexChapter: parseInt(this.indexChap)
      })
    },
    moveRequirement(e, toIndexRequir) {
      const fromIndexRequir = parseInt(e.dataTransfer.getData('requir-index'))
      const fromIndexChap = parseInt(e.dataTransfer.getData('chap-index'))

      this.$store.dispatch('moveRequirement', {
        fromIndexRequir,
        toIndexRequir,
        fromIndexChap,
        toIndexChap: this.indexChap
      })
    },
    moveQuestion(e, toIndexRequir) {
      const fromIndexQuestion = parseInt(e.dataTransfer.getData('question-index'))
      const fromIndexRequir = parseInt(e.dataTransfer.getData('requir-index'))
      const fromIndexChap = parseInt(e.dataTransfer.getData('chap-index'))
      const toIndexChap = parseInt(this.indexChap)
      const toIndexQuestion = parseInt(this.$store.state.surveyStruct.chapters[this.indexChap].requirements[toIndexRequir].questions.length)

      this.$store.dispatch('moveQuestion', {
        fromIndexQuestion,
        fromIndexRequir,
        fromIndexChap,
        toIndexQuestion,
        toIndexRequir,
        toIndexChap
      })
    },
    // delete alert
    confirmDelete(index){
      // store indexRequir locally because the deleteAccept() method can't receive arguments
      this.indexRequir = index
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: `Apagar o requisito '${this.reqtable[this.indexRequir].text}'? `,
        accept: this.deleteAccept
      })
    },
    deleteAccept(){
      this.$store.dispatch('deleteRequirement', {indexChap: this.indexChap, indexRequir: this.indexRequir}).then(() => {
        this.saveSurvey()
      })
      this.$vs.notify({
        color: 'success',
        title: 'Requisito apagado',
        text: 'O requisito selecionado foi apagado com sucesso'
      })
    },
  },
};
</script>
<style scoped>
button {
  outline: none !Important;
}

.requir-layout {
  background-color: #f3f4f6;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .05);
}

.stripe, .vs-table tr {
  background-color: transparent;
}

.active {
  display: none;
}

.vs-button {
  padding: 0 5px;
}

.c-text-sm {
  color: rgba(43, 43, 43, 0.6);
  font-size: 0.7rem;
  line-height: 1;
}

.c-cursor-default {
  cursor: default;
}
</style>
