<template>
  <div>
    <section
      v-if="questions.length"
      style="cursor:default;"
    >
      <vs-table
        stripe
        :data="questions"
      >
        <template slot-scope="{ data }">
          <vs-tr
            v-for="(tr, indexPergunt) in data"
            :key="indexPergunt"
          >
            <div
              class="d-flex"
              :draggable="checkItemAccess(['Editar Projetos Digitais'])"
              @mouseover="showItemOptions = indexPergunt"
              @mouseleave="showItemOptions = false"
              @dragstart="pickupItem($event, indexPergunt, indexRequir)"
              @drop="moveItem($event, indexPergunt, indexRequir)"
              @dragover.prevent
              @dragenter.prevent
            >
              <vs-td
                :data="tr.text"
                class="p-2"
              >
                <strong v-if="checkItemAccess(['Editar Projetos Digitais'])">● {{ tr.id + `: ` + tr.text }}</strong>
                <strong v-else>● {{ tr.text }}</strong>
                <br>
                <div
                  v-if="tr.config"
                  class="mb-0 d-flex"
                >
                  <span
                    v-for="(item, index) in tr.config.replace('n:x', '#max').split(';')"
                    :key="index"
                  >
                    <vs-chip
                      v-if="item === 'noinput'"
                      color="primary"
                      class="ml-1"
                      style="opacity: .5"
                    >
                      <vs-avatar
                        size="20px"
                        icon="keyboard"
                        style="cursor: default"
                      />
                      Sem digitação
                    </vs-chip>
                    <vs-chip
                      v-else-if="item === '#max'"
                      color="primary"
                      class="ml-1"
                      style="opacity: .5"
                    >
                      <vs-avatar
                        size="20px"
                        icon="star"
                        style="cursor: default"
                      />
                      Sem limite de resposta
                    </vs-chip>
                    <vs-chip
                      v-else-if="item.includes('n:')"
                      color="primary"
                      class="ml-1"
                      style="opacity: .5"
                    >
                      <vs-avatar
                        size="20px"
                        icon="star"
                        style="cursor: default"
                      />
                      Num. de resp: &nbsp;{{ item.split(':')[1] }}
                    </vs-chip>
                    <vs-chip
                      v-if="item.includes('optlist')"
                      color="primary"
                      class="ml-1"
                    >
                      <span
                        class="d-flex align-items-center"
                        style="cursor:pointer;"
                        @click="openListPreview(item, tr.options)"
                      >
                        <vs-avatar
                          size="20px"
                          icon="menu"
                          style="cursor: default"
                        />
                        <b>Opções:</b> &nbsp; {{ item.split(':')[1] }}
                      </span>
                    </vs-chip>
                    <vs-chip
                      v-if="item.includes('escape')"
                      color="primary"
                      class="ml-1"
                      style="opacity: .5"
                    >
                      <vs-avatar
                        size="20px"
                        icon="forward"
                        style="cursor: default"
                      />
                      <b>Opção de escape:</b> &nbsp; {{ item.includes('=') ? item.split('=')[1] : item.split(':')[1] }}
                    </vs-chip>
                    <vs-chip
                      v-if="item.includes('subquestid')"
                      color="primary"
                      class="ml-1"
                      style="opacity: .5"
                    >
                      <vs-avatar
                        size="20px"
                        icon="forward"
                        style="cursor: default"
                      />
                      <b>SubQuestId:</b> &nbsp; {{ item.split(':')[1] }}
                    </vs-chip>
                  </span>
                </div>
              </vs-td>
              <vs-td
                :data="tr.id"
                class="mr-0 p-2"
                style="width: 10rem"
              >
                <transition name="fadeOptions">
                  <div
                    v-if="showItemOptions === indexPergunt && checkItemAccess(['Editar Projetos Digitais', 'Votacoes'])"
                    class="pl-2 ml-auto"
                  >
                    <vs-button
                      radius
                      color="transparent"
                      class="text-dark"
                      title="Exportar pergunta"
                      @click="() => exportQuestionCSV(tr.id, tr.text)"
                    >
                      <i
                        class="ml-2 fa fa-download"
                        aria-hidden="true"
                      />
                    </vs-button>

                    <vs-button
                      radius
                      color="transparent"
                      class="text-dark"
                      title="Editar pergunta"
                      @click="openUpdate(indexPergunt, 'edit')"
                    >
                      <i
                        class="ml-2 fa fa-edit"
                        aria-hidden="true"
                      />
                    </vs-button>
                    <vs-button
                      v-if="checkItemAccess(['Editar Projetos Digitais'])"
                      radius
                      color="transparent"
                      class="text-dark"
                      title="Excluir pergunta"
                      @click.stop="confirmDelete(indexPergunt)"
                    >
                      <i
                        class="ml-2 fa fa-trash"
                        aria-hidden="true"
                      />
                    </vs-button>
                  </div>
                </transition>
              </vs-td>
            </div>
          </vs-tr>
          <vs-tr
            v-if="checkItemAccess(['Editar Projetos Digitais'])"
            style="background-color: transparent"
          >
            <vs-td class="p-2">
              <p
                class="mb-0 c-text-sm"
                title="Adicionar pergunta"
                @click="openUpdate(0, 'add')"
              >
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                /> Nova pergunta
              </p>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </section>

    <!-- If there is no question, show this just to receive a draggable dropped question -->
    <section v-else>
      <div
        class="bg-white"
        style="border-radius: 5px"
        @drop="moveItem($event, indexPergunt, indexRequir)"
        @dragover.prevent
        @dragenter.prevent
      >
        <p
          class="mb-0 p-2 c-text-sm"
          title="Adicionar pergunta"
          @click="openUpdate(0, 'add')"
        >
          <i
            class="fa fa-plus"
            aria-hidden="true"
          /> Nova pergunta
        </p>
      </div>
    </section>

    <vs-popup
      class="h-100"
      :title="questAction === 'add' ? 'Adicionar pergunta' : 'Editar pergunta'"
      :active.sync="isUpdateOpen"
    >
      <UpdateQuestion
        v-if="isUpdateOpen"
        :action="questAction"
        :index-chap="indexChap"
        :index-requir="indexRequir"
        :index-quest="indexQuest"
        @close-update="closeUpdate"
      />
    </vs-popup>

    <!-- List preview -->
    <vs-popup
      class="h-100"
      title="Opções de respostas"
      :active.sync="isListPreviewOpen"
      style="z-index: 31000"
    >
      <div
        id="listItemsLoading"
        class="vs-con-loading__container"
      />

      <vs-table
        id="listItemsContent"
        ref="listItemsContent"
        stripe
        pagination
        max-items="12"
        class="table table-striped"
        search
        :data="listItemsArray"
      >
        <template slot="header">
          <div class="d-flex justify-content-between align-items-center w-100 my-3">
            <h3 class="text-dark ml-3">
              Lista: {{ listName }}
            </h3>
          </div>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(item,indextr) in data"
            :key="indextr"
          >
            <vs-td
              :data="data[indextr]"
              class="py-1"
            >
              {{ data[indextr].item }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js';

/* Components */
import UpdateQuestion from '@/views/dashboards/dashboard-components/survey-structure/UpdateQuestion';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

export default {
  name: 'QuestTable',
  components: { UpdateQuestion },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    indexChap: {
      type: Number,
      required: true,
    },
    indexRequir: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isUpdateOpen: false,
    indexQuest: 0,
    questAction: null,
    showItemOptions: false,
    isListPreviewOpen: false,
    listName: '',
    listItemsArray: [],
  }),
  computed: {
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  methods: {
    checkItemAccess(access) {
      if (!access.length) {
        return true
      } else {
        let resp = false;

        access.forEach((i) => {
          if (store.state.loginData.accessValues.includes(i)) {
            resp = true;
          }
        });

        return resp;
      }
    },
    exportQuestionCSV(questionId, fileName) {
      store.dispatch('set_isLoadingActive', true);

      surveyService.exportQuestion(
        store.state.surveyStruct.surveyId,
        questionId,
        this.token,
      )
        .then((resp) => {
          const csvData = new Blob([resp], { type: 'text/csv' });
          const csvURL = URL.createObjectURL(csvData);
          const tempLink = document.createElement('a');

          const cleanName = String(fileName)
            .trim()
            .toLowerCase()
            .replaceAll(' ', '-');

          tempLink.href = csvURL;
          tempLink.setAttribute('download', cleanName);
          tempLink.click();
        })
        .catch((err) => alert(err))
        .finally(() => store.dispatch('set_isLoadingActive', false));
    },
    openUpdate(index, action) {
      this.indexQuest = index
      this.questAction = action
      this.isUpdateOpen = true
    },
    openListPreview(item, options) {
      this.$refs.listItemsContent.searchx = '';

      store.dispatch('set_isLoadingActive', true);

      if (item.includes('optlist')) {
        const listItem = item.split(':')[1];

        this.listName = listItem;

        var urlItem = listItem.startsWith('g/') ? listItem : store.state.surveyStruct.surveyId + '/' + listItem;

        surveyService.getSurveyList(urlItem, this.token)
          .then(ret => {
            // transform 'ret' in a list of objects, otherwise the search will not work
            this.listItemsArray = ret.map((el) => ({ item: el }));
          })
          .finally(() => store.dispatch('set_isLoadingActive', false));
      } else {
        this.listItemsArray = options.map((el) => ({ item: el.text }));

        store.dispatch('set_isLoadingActive', false);
      }

      this.isListPreviewOpen = true;
    },
    closeUpdate() {
      this.questAction = '';
      this.isUpdateOpen = false;
    },
    pickupItem(e, indexQuestion, indexRequir) {
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'move';

      e.dataTransfer.setData('question-index', indexQuestion);
      e.dataTransfer.setData('requir-index', indexRequir);
      e.dataTransfer.setData('chap-index', this.indexChap);
      e.dataTransfer.setData('type', 'question');
    },
    moveItem(e, toIndexQuestion, toIndexRequir) {
      if (e.dataTransfer.getData('type') === 'chapter') {
        this.moveChapter(e);
      } else if (e.dataTransfer.getData('type') === 'requirement') {
        this.moveRequirement(e, toIndexRequir);
      } else if (e.dataTransfer.getData('type') === 'question') {
        this.moveQuestion(e, toIndexQuestion, toIndexRequir);
      }

      this.saveSurvey();
    },
    moveChapter(e) {
      const fromIndexChapter = parseInt(e.dataTransfer.getData('chap-index'));

      store.dispatch('moveChapter', {
        fromIndexChapter,
        toIndexChapter: parseInt(this.indexChap),
      });
    },
    moveRequirement(e, toIndexRequir) {
      const fromIndexRequir = parseInt(e.dataTransfer.getData('requir-index'));
      const fromIndexChap = parseInt(e.dataTransfer.getData('chap-index'));
      const toIndexChapter = parseInt(this.indexChap);

      store.dispatch('moveRequirement', {
        fromIndexRequir,
        toIndexRequir,
        fromIndexChap,
        toIndexChap: toIndexChapter,
      });
    },
    moveQuestion(e, toIndexQuestion, toIndexRequir) {
      const fromIndexQuestion = parseInt(e.dataTransfer.getData('question-index'));
      const fromIndexRequir = parseInt(e.dataTransfer.getData('requir-index'));
      const fromIndexChap = parseInt(e.dataTransfer.getData('chap-index'));

      store.dispatch('moveQuestion', {
        fromIndexQuestion,
        fromIndexRequir,
        fromIndexChap,
        toIndexQuestion,
        toIndexRequir,
        toIndexChap: this.indexChap,
      });
    },
    // delete alert
    confirmDelete(index){
      // store indexRequir locally because the deleteAccept() method can't receive arguments
      this.indexQuest = index;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm',
        text: `Apagar a pergunta '${this.questions[this.indexQuest].text}'? `,
        accept: this.deleteAccept,
      });
    },
    deleteAccept(){
      store.dispatch('deleteQuestion', {
        indexChap: this.indexChap,
        indexRequir: this.indexRequir,
        indexQuest: this.indexQuest,
      })
        .then(() => {
          this.saveSurvey();
        });

      this.$vs.notify({
        color: 'success',
        title: 'Sucesso',
        text: 'A pergunta foi apagada',
      });
    },
    saveSurvey() {
      store.dispatch('saveSurveyStruct')
        .then(() => {
          this.$vs.notify({
            title: 'Salvo',
            text: 'Questionário salvo',
            color: 'success',
            icon: 'check_box',
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Erro ao salvar',
            text: `Tente salvar manualmente: (${err})`,
            color: 'danger', icon: 'warning',
          });
        })
    },
  }
};
</script>

<style scoped>
.stripe {
  background: white;
}

.vs-button--text {
  height: 0px;
}

.vs-button {
  padding: 0 5px;
}

.c-text-sm {
  color: rgba(43, 43, 43, 0.5);
  font-size: 0.7rem;
  line-height: 1;
}

.c-chip {
  background-color: #2b2b2b;
  border-radius: 10px;
  border: 1px solid rgba(43, 43, 43, 0.5);
}

.con-vs-chip {
  min-height: 20px;
}
</style>
