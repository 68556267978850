<template>
  <div
    id="tableQuestion"
    class="con-tab-ejemplo"
    @keydown.esc="$emit('close-update')"
  >
    <div
      v-if="action === 'add'"
      class="row m-2 d-flex justify-content-start"
    >
      <div class="col-sm-12 col-md-4 col">
        <vs-button
          color="success"
          @click="popupActivo_listQuestions = true"
        >
          Escolher uma pergunta
        </vs-button>
      </div>
      <div class="col-sm-12 col-md-4 col">
        <vs-button
          color="success"
          @click="setNewQuestion()"
        >
          Criar uma nova pergunta
        </vs-button>
      </div>
    </div>
    <vs-popup
      class="h-100"
      title="Escolher uma pergunta pré-cadastrada"
      :active.sync="popupActivo_listQuestions"
      style="z-index: 30000"
    >
      <div @keydown.prevent.esc="popupActivo_listQuestions = false">
        <!-- surveyGlobalQuestions Table -->
        <vs-table
          stripe
          search
          pagination
          max-items="8"
          class="table table-striped col-sm-12 col-md-12"
          :data="globalQuestions"
        >
          <template slot="header">
            <h3>
              Lista de perguntas globais
            </h3>
          </template>
          <template slot="thead">
            <vs-th class="p-1">
              Texto da questão:
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr
              v-for="(item,indextr) in data"
              :key="indextr"
            >
              <vs-td :data="data[indextr].questionId">
                <vs-radio
                  v-model="selectedQuestion"
                  class="d-inline-flex"
                  color="success"
                  tabindex="0"
                  vs-name="data[indextr].questionId"
                  :vs-value="data[indextr]"
                >
                  {{ data[indextr].text }}
                </vs-radio>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <br>
        <vs-button
          color="success"
          class="m-2 w-100 col-sm-12 col-md-5"
          @click="loadQuestion()"
        >
          Confirmar
        </vs-button>
      </div>
    </vs-popup>

    <div class="row m-2">
      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <label
          for="txtId"
          class="ml-2 font-weight-bold"
        >Código</label>
        <vs-input
          id="txtId"
          v-model="questLocal.id"
          name="txtId"
          disabled
        />
      </div>
      <div class="m-2 col-sm-12 col-md-12 col">
        <div class="">
          <label
            for="txtText"
            class="ml-2 font-weight-bold"
          >Texto</label>
          <vs-input
            id="txtText"
            v-model="questLocal.text"
            class="inputx w-100"
            name="txtText"
          />
          <div class="mt-2 d-flex">
            <vs-button
              class="ml-2"
              size="small"
              @click="questLocal.text = questLocal.text.toUpperCase()"
            >
              Uppercase
            </vs-button>
            <vs-button
              class="ml-2"
              size="small"
              @click="questLocal.text = questLocal.text.toLowerCase(); questLocal.text = questLocal.text[0].toUpperCase() + questLocal.text.slice(1)"
            >
              Propercase
            </vs-button>
          </div>
        </div>
      </div>
      <div class="m-2 col-sm-12 col-md-12 col">
        <div class="">
          <label
            for="txtDescription"
            class="ml-2 font-weight-bold"
          >Descrição</label>
          <vs-input
            id="txtDescription"
            v-model="questLocal.description"
            class="inputx w-100"
            name="txtDescription"
          />
        </div>
      </div>
      <div class="m-2 col-sm-12 col-md-12 col">
        <div class="">
          <label
            for="txtObs"
            class="ml-2 font-weight-bold"
          >Observação</label>
          <vs-input
            id="txtObs"
            v-model="questLocal.obs"
            name="txtObs"
            class="inputx w-100"
          />
        </div>
      </div>
      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <label for="TipoResposta">Tipo de Resposta</label>
        <select
          id="TipoResposta"
          v-model="questLocal.type"
          autocomplete="on"
          tabindex="0"
          name="Tipo de Resposta"
          class="c-input"
        >
          <option
            v-for="(item, index) in questionTypes"
            :key="index"
            :value="item.type"
          >
            {{ item.description }}
          </option>
        </select>
      </div>

      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="m-2 col-sm-12 col-md-3 col mt-3"
      >
        <div class="d-flex">
          <label
            class="m-2"
            for="swMandatory"
          >Mandatory</label>
          <vs-switch
            id="swMandatory"
            v-model="questLocal.mandatory"
            class="mt-1"
            color="success"
          />
        </div>
      </div>
      <div
        v-if="questLocal.type === 4"
        class="m-2 mt-3"
      >
        <div class="col-sm-12 col-md-6 col d-flex">
          <label
            class="m-2"
            for="swMandatoryReq"
          >MandatoryReq</label>
          <vs-switch
            id="swMandatoryReq"
            v-model="questLocal.mandatoryReq"
            class="mt-1"
            color="success"
          />
        </div>
      </div>

      <!-- Substitute Question ID section -->
      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="mx-2 col-sm-12 col-md-12 col"
      >
        <div class="d-flex">
          <label
            class="mx-2 mt-2 align-self-center"
            for="swSubsQuestId"
          >Question Id Substituto</label>
          <vs-switch
            id="swSubsQuestId"
            v-model="swSubsQuestId"
            color="success"
            class="align-self-center"
            @input="swSubsQuestIdChange"
          />
        </div>
        <div
          v-if="swSubsQuestId"
          class="col-md-6 ml-3 mb-1 d-flex"
        >
          <label
            class="mx-2 mt-2 align-self-center"
            for="swSubsQuestId"
          >Id da questão:</label>
          <vs-input
            v-model.number="subsQuestId"
            type="number"
            min="0"
            class="col-md-3"
          />
        </div>
        <div
          v-if="swSubsQuestId"
          class="col-md-12 ml-3 d-flex"
        >
          <label
            class="mx-2 mt-2 align-self-center"
            for=""
          >Resposta a ser ignorada:</label>
          <vs-input
            v-model="subsQuestIdText"
            class="col-md-5"
            style=""
          />
        </div>
      </div>

      <!-- Address section -->
      <section
        v-if="questLocal.type === 6"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <!-- List details -->
        <div class="d-flex m-2 mt-0">
          <label
            for="addressOptSw"
            class="mr-2"
          >Formulário de endereço completo:</label>
          <vs-switch
            id="addressOptSw"
            v-model="addressOptSw"
            class="mt-n1"
            color="success"
            name="addressOptSw"
          />
        </div>
      </section>

      <!-- Multi text section-->
      <section
        v-if="questLocal.type === 8"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <ul class="d-flex m-2 mb-3 col-sm-12 col list-inline">
          <li class="mb-2">
            Quantidade de campos:
          </li>
          <vs-switch
            v-model="listQtSw"
            class="mt-n1 mx-2"
            color="success"
            name="listQtSw"
            @change="listQtSwChange()"
          >
            <span slot="off">ilimitado</span>
          </vs-switch>
          <vs-input-number
            v-if="listQtValue !== 'x'"
            v-model="listQtValue"
            class="mt-n2 ml-2"
            color="success"
            min="1"
          />
        </ul>
      </section>

      <!-- Lists section-->
      <section
        v-if="questLocalTypeHasAList"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <div class="row m-2">
          <p class="mb-3 d-block font-weight-bold">
            OPÇÕES DE LISTA
          </p>
        </div>
        <!-- List details -->
        <div class="d-flex m-2 mt-0">
          <label
            for="listInputSw"
            class="mr-2"
          >Campo de digitação para filtrar as opções</label>
          <vs-switch
            id="listInputSw"
            v-model="listInputSw"
            class="mt-n1"
            color="success"
            name="listInputSw"
          />
        </div>
        <div class="d-flex m-2 mt-0">
          <label
            for="inputOther"
            class="mr-2"
          >Incluir opção de texto livre "Outro" </label>
          <vs-switch
            id="inputOther"
            v-model="inputOther"
            class="mt-n1"
            color="success"
            name="listinputOtherInputSw"
          />
        </div>
        <div
          v-if="inputOther"
          class="col-md-10 ml-3 mb-1 d-flex"
        >
          <label
            class="mx-2 mt-2 mb-3 align-self-center"
            for="swSubsQuestId"
          >Texto do botão:</label>
          <vs-input
            v-model="buttonTextOther"
            type="text"
            min="0"
            class="col-md-6"
          />
        </div>
        <div
          v-if="inputOther"
          class="col-md-10 ml-3 mb-1 d-flex"
        >
          <label
            class="mx-2 mt-2 mb-3 align-self-center"
            for="swSubsQuestId"
          >Título da questão:</label>
          <vs-input
            v-model="inputTextOther"
            type="text"
            min="0"
            class="col-md-6"
          />
        </div>
        <ul class="d-flex m-2 mb-4 col-sm-12 col list-inline">
          <li class="mb-2">
            Limite de respostas possíveis:
          </li>
          <vs-switch
            v-model="listQtSw"
            class="mt-n1 mx-2"
            color="success"
            name="listQtSw"
            @change="listQtSwChange()"
          >
            <span slot="off">ilimitado</span>
          </vs-switch>
          <vs-input-number
            v-if="listQtValue !== 'x'"
            v-model="listQtValue"
            class="mt-n2 ml-2"
            color="success"
            min="1"
          />
        </ul>
        <!-- Lists selection -->
        <div class="d-flex align-items-end justify-content-between">
          <p
            class="pl-2 font-weight-bold mb-1"
            style="font-size: 0.85rem"
          >
            Lista selecionada:
          </p>
          <div class="d-flex ml-1 mb-2 justify-content-end">
            <vs-button
              class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Procurar lista"
              @click="isFindListOpen = true"
            >
              <i
                id="findList"
                class="fas fa-search"
                aria-hidden="true"
              /> Procurar Lista
            </vs-button>
            <vs-button
              class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Criar nova lista"
              @click="openUpdateList('add')"
            >
              <i
                id="findList"
                class="fas fa-plus"
                aria-hidden="true"
              /> Criar Nova
            </vs-button>
          </div>
        </div>
        <vs-table
          v-if="selectedList"
          id="listItemsContent"
          stripe
          pagination
          max-items="12"
          class="table table-striped"
          :data="listItemsArray"
        >
          <template slot="header">
            <div class="d-flex w-100 justify-content-between align-items-baseline">
              <h4 class="p-3">
                {{ selectedList }} {{ selectedListOrigin === 'g/' ? '(global)' : '' }}
              </h4>
              <div class="d-flex">
                <vs-button
                  radius
                  color="transparent"
                  class="text-dark"
                  title="Editar Lista"
                  @click.stop="openUpdateList('edit')"
                >
                  <i
                    class="fas fa-edit ml-2"
                    aria-hidden="true"
                  />
                </vs-button>
                <vs-button
                  radius
                  color="transparent"
                  class="text-secondary"
                  title="Excluir lista"
                  @click.stop="confirmDelete(selectedList)"
                >
                  <i
                    class="fas fa-trash ml-2"
                    aria-hidden="true"
                  />
                </vs-button>
              </div>
            </div>
          </template>
          <template slot="thead">
            <vs-th class="p-1">
              Itens da lista
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr
              v-for="(item,indextr) in data"
              :key="indextr"
            >
              <vs-td
                :data="data[indextr]"
                class="py-1"
              >
                {{ data[indextr] }} <b>{{ listEscape !== data[indextr] ? '' : ' (escape)' }}</b>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </section>

      <!-- Gallery section-->
      <section
        v-if="questLocal.type === 9 || questLocal.type === 12"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <div class="m-2 d-flex align-items-end justify-content-between">
          <h4 class="mb-3 d-block font-weight-bold">
            OPÇÕES DE GALERIA
          </h4>
          <div class="d-flex ml-1 mb-2 justify-content-end">
            <!--            <vs-button @click="isFindListOpen = true"-->
            <!--                       class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"-->
            <!--                       title="Procurar lista"-->
            <!--            >-->
            <!--              <i id="findList" class="fas fa-search" aria-hidden="true"></i> Procurar Lista-->
            <!--            </vs-button>-->
          </div>
        </div>

        <!-- List table -->
        <vs-table
          v-if="questLocal.options"
          id="listItemsContent"
          stripe
          pagination
          max-items="12"
          class="table table-striped w-100"
          :data="questLocal.options"
        >
          <template slot="thead">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
              <h4>Itens da lista atual</h4>
              <div class="d-flex">
                <vs-button
                  type="border"
                  class="ml-2 text-dark"
                  title="Criar nova lista"
                  @click="galleryListAction = 'add'; isUpdateGalleryListOpen = true"
                >
                  <i
                    id="findList"
                    class="fas fa-plus"
                    aria-hidden="true"
                  /> Criar Nova Lista
                </vs-button>
                <vs-button
                  class="ml-2 border-0 bg-transparent"
                  title="Editar Galeria"
                  @click.stop="galleryListAction = 'edit'; isUpdateGalleryListOpen = true"
                >
                  <i
                    class="fas fa-edit ml-2"
                    aria-hidden="true"
                  /> Editar Lista
                </vs-button>
              </div>
            </div>
          </template>
          <template slot-scope="{data}">
            <vs-tr
              v-for="(item,indextr) in data"
              :key="indextr"
            >
              <vs-td
                :data="data[indextr]"
                class="py-1"
              >
                <a
                  target="_blank"
                  :href="data[indextr].ilustration"
                >{{ data[indextr].text }}</a><b>{{ listEscape !== data[indextr] ? '' : ' (escape)' }}</b>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </section>

      <!-- Childs and Dependents section-->
      <section
        v-if="questLocal.type === 13"
        class="m-2 col-sm-12 col-md-12 col"
      >
        <p class="mb-3 d-block font-weight-bold">
          OPÇÕES DE <i>CRIANÇAS E DEPENDENTES</i>
        </p>
        <p>
          Essa opção mostrará uma quantidade X de campos de "Idade" e "Nome Completo", dependendo do número (X) de
          <i>Filhos</i> ou <i>Dependentes</i> essa pessoa selecionar.
        </p>
        <ul class="d-flex m-2 mb-3 col-sm-12 col list-inline">
          <li class="mb-2">
            Quantidade máxima:
          </li>
          <vs-input-number
            v-model="dependentsQtValue"
            class="mt-n2 ml-3"
            color="success"
            min="2"
          />
        </ul>
      </section>

      <br>

      <vs-button
        color="success"
        class="m-2 w-100 col-sm-12 col-md-12"
        :disabled="!questLocal.text"
        @click="saveQuestion()"
      >
        {{ !questLocal.text ? 'Insira um texto à pergunta' : 'Salvar' }}
      </vs-button>
    </div>

    <vs-popup
      class="h-100"
      title="Procurar lista"
      :active.sync="isFindListOpen"
      style="z-index: 30000"
    >
      <!-- New List section-->
      <FindList
        v-if="isFindListOpen"
        @select-list="selectList"
        @close-update="isFindListOpen = false"
      />
    </vs-popup>
    <vs-popup
      class="h-100"
      :title="listAction === 'add' ? 'Adicionar lista' : 'Editar lista'"
      :active.sync="isUpdateListOpen"
      style="z-index: 30000"
    >
      <!-- Update List section-->
      <UpdateList
        v-if="isUpdateListOpen"
        :action="listAction"
        :list-origin="selectedListOrigin"
        :list-name="selectedList"
        :list-items-array="listItemsArray"
        :list-escape="listEscape"
        @close-update="isUpdateListOpen = false"
        @save-update="saveUpdateList"
      />
    </vs-popup>
    <!-- Update Gallery List popup -->
    <vs-popup
      class="h-100 c-gallery-popup"
      :title="listAction === 'add' ? 'Adicionar lista de Galeria' : 'Editar lista de Galeria'"
      :active.sync="isUpdateGalleryListOpen"
      style="z-index: 30000"
    >
      <!-- Update List section-->
      <component
        :is="questLocal.type === 9 ? 'UpdateGalleryList' : 'UpdateVideoGalleryList'"
        v-if="isUpdateGalleryListOpen"
        :action="galleryListAction"
        :list-items-array="questLocal.options"
        @close-update="isUpdateGalleryListOpen = false"
        @save-update="saveGalleryList"
      />
    </vs-popup>
  </div>
</template>
<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js';

/* Components */
import FindList from '@/views/dashboards/dashboard-components/survey-structure/FindList';
import UpdateList from '@/views/dashboards/dashboard-components/survey-structure/UpdateList';
import UpdateGalleryList from '@/views/dashboards/dashboard-components/survey-structure/UpdateGalleryList';
import UpdateVideoGalleryList from '@/views/dashboards/dashboard-components/survey-structure/UpdateVideoGalleryList';

/* Services */
import Survey from '@/services/survey';

const ss = new Survey();

export default {
  name: 'UpdateQuestion',
  components: {FindList, UpdateList, UpdateGalleryList, UpdateVideoGalleryList},
  props: {
    action: String,
    indexChap: Number,
    indexRequir: Number,
    indexQuest: Number,
  },
  data: () => ({
    questLocal: {},

    popupActivo_listQuestions: false,
    selectedQuestion: [],

    listInputSw: false,
    inputOther: false,
    inputTextOther: '',
    buttonTextOther: '',
    listQtSw: true,
    listQtValue: 1,
    listEscape: null,
    selectedList: '',
    selectedListOrigin: '',

    dependentsQtValue: 2,

    isFindListOpen: false,
    isUpdateListOpen: false,
    listAction: '',

    isUpdateGalleryListOpen: false,
    galleryListAction: '',

    listItemsArray: [],

    addressOptSw: false,

    swSubsQuestId: false,
    subsQuestId: 0,
    subsQuestIdText: '',

  }),
  computed: {
    questionTypes() {
      return store.state.questionTypes
    },
    globalQuestions() {
      return store.state.surveyGlobalQuestions.questions
    },
    questionHasAnswersQuantityOption() {
      return (this.questLocal.type === 3 || this.questLocal.type === 5 || this.questLocal.type === 8)
    },
    questLocalTypeHasAList() {
      return (this.questLocal.type === 3 || this.questLocal.type === 5)
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    popupActivo_listQuestions() {
      if (this.popupActivo_listQuestions) {
        this.$nextTick(() => {
          document.getElementsByTagName('input')[0].focus()
        })
      } else {
        this.$nextTick(() => {
          document.getElementById('txtText').focus()
        })
      }
    },
    listQtValue() {
      if (this.listQtValue === 'x') this.questLocal.obs = '(Selecione todas que se aplicam)'
      else if (this.listQtValue === '1') this.questLocal.obs = '(Resposta única)'
      else this.questLocal.obs = `(Selecione até ${this.listQtValue} alternativas)`
    },
  },
  created() {
    // if is editing, clone the question object
    if (this.action === 'edit') {
      this.questLocal = JSON.parse(JSON.stringify(
        store.state.surveyStruct.chapters[this.indexChap].requirements[this.indexRequir].questions[this.indexQuest]
      ))
      this.treatConfigValuesToDisplay()

    // else (if is adding), create a fresh new one
    } else {
      this.$store.dispatch('getSurveyGlobalQuestions')
      this.questLocal = this.createFreshQuestionObject()
    }
  },
  mounted() {
    if (this.action === 'edit') document.getElementById('txtText').focus()
    else {
      this.popupActivo_listQuestions = true
      this.$nextTick(() => {
        document.getElementsByTagName('input')[0].focus()
      })
    }
  },
  methods: {
    checkItemAccess(access) {
      if (!access.length) return true
      else {
        let resp = false
        access.forEach(i => {
          if (store.state.loginData.accessValues.includes(i)) {
            resp = true
          }
        })
        return resp
      }
    },
    treatConfigValuesToDisplay() {
      // set the config value to an array for best handling (separating configs)
      if (this.questLocal.config === null) this.questLocal.config = ''
      const questionConfigArray = this.questLocal.config.split(';')
      // set the selectedList text
      if (questionConfigArray.some(e => e.startsWith('optlist'))) {
        const optlist = questionConfigArray.filter(e => e.startsWith('optlist'))[0].split(':')[1]
        this.setSelectedListAndOrigin(optlist)
        this.updatelistItemsArray()
      }
      // set the listInputSw
      if (this.action === 'add') {
        this.listInputSw = false
      } else this.listInputSw = !questionConfigArray.some(e => e.startsWith('noinput'));
      // set the inputOther
      if (this.action === 'add') {
        this.inputOther = false
      } else
      {
        this.inputOther = questionConfigArray.some(e => e.startsWith('otherText'));
        for (let i = 0; i < questionConfigArray.length; i++){
          if (questionConfigArray[i].startsWith('otherText=')){
            const resultado = questionConfigArray[i];
            const index = resultado.indexOf('&');
            const value = resultado.substring(index + 1);
            this.inputTextOther = value.replaceAll('_',' ');
          }
        }
        for (let i = 0; i < questionConfigArray.length; i++){
          if (questionConfigArray[i].startsWith('otherText=')){
            const resultado = questionConfigArray[i];
            const index = resultado.indexOf('=');
            const limit = resultado.indexOf('&');
            const value = resultado.substring(index + 1, limit);
            this.buttonTextOther = value.replaceAll('_',' ');
          }
        }
      }

      // set the addressOptSw
      if (this.action === 'add') {
        this.listInputSw = false
      } else this.addressOptSw = questionConfigArray.some(e => e.startsWith('completeAddressForm'));

      // set the Substitute Question Id
      if (this.action === 'add') {
        this.swSubsQuestId = false
      } else if (questionConfigArray.some(e => e.startsWith('subquestid:'))) {
        this.swSubsQuestId = true
        const subsQuestIdValue = questionConfigArray.filter(e => e.startsWith('subquestid:'))[0].split(':')[1]
        if (subsQuestIdValue.includes('-')) {
          this.subsQuestId = subsQuestIdValue.split('-')[0]
          this.subsQuestIdText = subsQuestIdValue.split('-')[1]
        } else this.subsQuestId = subsQuestIdValue
      }

      // set the listQtValue
      if (questionConfigArray.some(e => e.startsWith('n:'))) {
        this.listQtValue = questionConfigArray.filter(e => e.startsWith('n:'))[0].split(':')[1]
        if (this.listQtValue === 'x') {
          this.listQtSw = false
          this.questLocal.obs = '(Selecione todas que se aplicam)'
        } else {
          this.listQtSw = true
          if (this.listQtValue === '1') {
            this.questLocal.obs = '(Resposta única)'
          } else {
            this.questLocal.obs = `(Selecione até ${this.listQtValue} alternativas)`
          }
        }
      } else {
        if (this.questionHasAnswersQuantityOption) {
          this.listQtValue = '1';
          this.listQtSw = true;
          this.questLocal.obs = '(Resposta única)';
        }
      }
      // set the listEscape
      if (questionConfigArray.some(e => e.startsWith('escape'))) {
        this.listEscape = questionConfigArray.filter(e => e.startsWith('escape'))[0].split('=')[1];
      }
      // set the dependentsQtValue
      if (this.questLocal.type === 13) {
        this.dependentsQtValue = this.questLocal.options.length;
      }
    },
    updatelistItemsArray() {
      const urlItem = this.selectedListOrigin + this.selectedList;

      ss.getSurveyList(urlItem, this.token)
        .then(ret => {
          this.listItemsArray = ret;
        })
        .catch(err => {
          this.listItemsArray = '';
          alert('Houve um problema no carregamento da lista inserida. \r\nVerifique se o nome está correto.\r\n \r\n' + err)
        })
    },
    setSelectedListAndOrigin(value) {
      if (value.startsWith('g/')) {
        this.selectedListOrigin = 'g/';
        this.selectedList = value.split('/')[1];
      } else {
        this.selectedListOrigin = store.state.surveyStruct.surveyId + '/';
        this.selectedList = value;
      }
    },
    createFreshQuestionObject() {
      return {
        id: 'auto',
        text: '',
        description: '',
        obs: '',
        placeHolder: '',
        type: '',
        mandatory: true,
        mandatoryReq: false,
        factor: 0,
        criteriaType: 0,
        criteriaAux: '',
        class: '',
        config: '',
        repository: null,
        field: null,
        upload: null,
        options: [],
        answer: null
      }
    },
    loadQuestion() {
      this.questLocal = this.createFreshQuestionObject()
      this.questLocal.id = this.selectedQuestion.questionId
      this.questLocal.text = this.selectedQuestion.text
      this.questLocal.type = this.selectedQuestion.type
      if (this.selectedQuestion.options) this.questLocal.options = this.selectedQuestion.options
      if (this.selectedQuestion.listName) this.questLocal.config = 'optlist:' + this.selectedQuestion.listName
      this.treatConfigValuesToDisplay()
      this.popupActivo_listQuestions = false
    },
    setNewQuestion() {
      this.questLocal = this.createFreshQuestionObject()
    },
    listQtSwChange() {
      if (this.listQtSw) {
        this.listQtValue = '1'
      } else {
        this.listQtValue = 'x'
      }
    },
    swSubsQuestIdChange() {
      if (this.swSubsQuestId === false) {
        this.subsQuestId = 0
      }
    },
    selectList(value) {
      this.setSelectedListAndOrigin(value)
      this.isFindListOpen = false
      this.updatelistItemsArray()
      this.$nextTick(() => {
        document.getElementById('txtText').focus()
      })
    },
    openUpdateList(action) {
      this.listAction = action
      this.isUpdateListOpen = true
    },
    saveUpdateList(listOrigin, listName, listItemsArray, listEscape) {
      this.selectedListOrigin = listOrigin
      this.selectedList = listName
      this.listItemsArray = listItemsArray
      this.listEscape = listEscape
      this.isUpdateListOpen = false
      this.$nextTick(() => {
        document.getElementById('txtText').focus()
      })
    },
    saveGalleryList(listItemsArray) {
      this.selectedList = ''
      this.questLocal.options = listItemsArray
      this.isUpdateGalleryListOpen = false
      this.$nextTick(() => {
        document.getElementById('txtText').focus()
      })
    },
    confirmDelete(){
      this.$vs.dialog({
        color: 'success',
        title: 'Opção desabilitada',
        text: 'Contate o suporte',
      })
    },
    saveConfigField() {
      const configArray = []
      // set answers quantity
      if (this.questionHasAnswersQuantityOption) {
        configArray.push('n:' + this.listQtValue)
      }

      // set substitute question Id
      if (this.swSubsQuestId && this.subsQuestIdText) {
        configArray.push(`subquestid:${this.subsQuestId}-${this.subsQuestIdText}`)
      } else if (this.swSubsQuestId && !this.subsQuestIdText) {
        configArray.push('subquestid:' + this.subsQuestId)
      }

      // to list type
      if (this.questLocalTypeHasAList) {
        // set input
        if (!this.listInputSw) configArray.push('noinput')
        // set list path
        if (this.selectedList && this.selectedListOrigin === 'g/') {
          configArray.push('optlist:' + this.selectedListOrigin + this.selectedList)
        } else if (this.selectedList && this.selectedListOrigin !== 'g/') {
          configArray.push('optlist:' + this.selectedList)
        }
        // set escape list
        if (this.listEscape) configArray.push('escape=' + this.listEscape)
        if (this.inputOther){
          if(this.inputTextOther == '' && this.buttonTextOther == ''){
            configArray.push('otherText=Personalizada&' + 'Qual?')
          }
          else if(this.inputTextOther != '' && this.buttonTextOther == ''){
            configArray.push('otherText=Personalizada&' + this.inputTextOther)
          }
          else if(this.inputTextOther == '' && this.buttonTextOther != ''){
            configArray.push('otherText=' + this.buttonTextOther + '&' + 'Qual?')
          }
          else if(this.inputTextOther != '' && this.buttonTextOther != ''){
            configArray.push('otherText=' + this.buttonTextOther + '&' + this.inputTextOther)
          }
        }
      }

      // to address type
      if (this.questLocal.type === 6) {
        // set complete address form
        if (this.addressOptSw) configArray.push('completeAddressForm')
      }

      this.questLocal.config = configArray.join(';')
    },
    saveOptionsField() {
      // to childs and dependents
      if (this.questLocal.type === 13) {
        this.questLocal.options = []
        for(let i = 1; i <= this.dependentsQtValue; i++) {
          let obj = {}
          obj.value = String(i)
          obj.text = String(i)
          obj.ilustration = ''
          this.questLocal.options.push(obj)
        }
      }
    },
    saveSurvey() {
      store.dispatch('saveSurveyStruct').then(() => {
        this.$vs.notify({title: 'Salvo',text: 'Questionário salvo', color: 'success', icon: 'check_box'})
      }).catch((err) => {
        this.$vs.notify({title: 'Erro ao salvar', text: `Tente salvar manualmente: (${err})`, color: 'danger', icon: 'warning'})
      })
    },
    saveQuestion() {
      this.saveConfigField()
      this.saveOptionsField()
      if (this.action === 'edit') {
        let payload = {
          indexChap: this.indexChap,
          indexRequir: this.indexRequir,
          indexQuest: this.indexQuest,
          value: this.questLocal
        }
        store.dispatch('updateQuestion', payload).then(() => {
          this.saveSurvey()
        })
      } else {
        (async () => {
          if (this.questLocal.id === 'auto') {
            this.questLocal.id = await ss.getNewQuestionId(store.state.selectedOwner, this.token);
          }

          store
            .dispatch('addQuestion', {
              indexChap: this.indexChap,
              indexRequir: this.indexRequir,
              value: this.questLocal
            })
            .then(() => {
              this.saveSurvey();
            })
        })()
      }
      return this.$emit('close-update')
    }
  },
}
</script>
<style scoped>
h4, h5 {
  font-weight: 300;
}

.vs-table--tbody-table .tr-values td {
  padding: 0.5rem;
}
.c-input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 7px;
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
}
.c-gallery-popup >>> .vs-popup {
  width: 1200px;
}
</style>
