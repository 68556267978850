<template>
  <div @keydown.esc="$emit('close-update')">
    <div class="d-flex align-items-end mb-3">
      <vs-input
        v-model="localListName"
        class="w-50"
        label="Nome:"
        placeholder="lista_exemplo"
        :disabled="action === 'edit'"
      />
      <vs-button
        color="black"
        class="mx-2 float-right"
        :disabled="!localListName || localListItemsArray.length < 2"
        @click="sortList()"
      >
        Ordenar
      </vs-button>
      <vs-button
        color="success"
        class="mx-2 float-right"
        :disabled="!localListName || localListItemsArray.length < 2"
        @click="saveList()"
      >
        {{ localListName && localListItemsArray.length>=2 ? 'Salvar Lista' : 'Preencha os campos' }}
      </vs-button>
    </div>
    <div
      v-if="action === 'add'"
      class="d-flex"
    >
      <p class="pl-2 mt-2 font-weight-bold">
        Destino:
      </p>
      <vs-radio
        v-model="localListOrigin"
        class="ml-2"
        vs-name="localListOrigin"
        :vs-value="surveyID"
      >
        Survey {{ surveyID }}
      </vs-radio>
      <vs-radio
        v-model="localListOrigin"
        class="ml-2"
        vs-name="localListOrigin"
        vs-value="g/"
      >
        Global
      </vs-radio>
    </div>
    <div class="d-flex m-2">
      <label
        class="pr-2"
        for="swMultiline"
      >Adicionar vários itens de uma vez</label>
      <vs-switch
        id="swMultiline"
        v-model="swMultiline"
        color="success"
      />
    </div>
    <p class="pl-2 mt-2 font-weight-bold">
      Itens:
    </p>
    <div
      v-for="(item, index) in localListItemsArray"
      :key="index"
      class="item d-flex"
      draggable
      @mouseover="showOptions = index"
      @mouseleave="showOptions = false"
      @dragstart="pickupItem($event, index)"
      @drop="moveItem($event, index)"
      @dragover.prevent
      @dragenter.prevent
    >
      <input
        type="text"
        class="input-item"
        :value="localEscape !== item ? item : item + ' (escape)'"
        @keyup="updateItem($event, index)"
      >
      <div
        v-if="showOptions === index"
        class="d-flex w-50 justify-content-end"
      >
        <button
          class="mx-2 d-inline bg-transparent border-0"
          title="Definir escape"
          @click="localEscape === item ? localEscape = null : localEscape = item"
        >
          {{ localEscape === item ? 'Remover escape' : 'Definir escape' }}
        </button>
        <button
          class="mx-2 bg-transparent border-0"
          title="Remove item"
          @click="removeItem(item, index)"
        >
          <i
            id="findList"
            class="fas fa-trash"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
    <input
      v-if="!swMultiline"
      type="text"
      class="mb-4 input-item"
      placeholder="+ adicione um novo item"
      @keyup.enter="addItem($event)"
    >
    <div
      v-else
      class="ml-2"
    >
      <textarea
        id="list"
        v-model="inviteTextarea"
        name="list"
        width="90%"
        rows="5"
        cols="80"
        placeholder="Adicione aqui os items separados por linha"
        class="mt-2 p-2"
      />
      <vs-button
        class="px-3 mb-4 float-right mr-5"
        @click="generateList"
      >
        Processar lista
      </vs-button>
    </div>
  </div>
</template>

<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js';

export default {
  name: 'UpdateList',
  props: {
    action: {},
    listOrigin: String,
    listName: String,
    listItemsArray: Array,
    listEscape: String,
  },
  data: () => ({
    showOptions: false,
    localListOrigin: '',
    localListName: '',
    localListItemsArray: [],
    localEscape: null,
    swMultiline: false,
  }),
  computed: {
    surveyID() {
      return store.state.surveyStruct.surveyId
    }
  },
  created() {
    // if is editing, clone the question object
    if (this.action === 'edit') {
      this.localListItemsArray = [...this.listItemsArray]
      this.localListOrigin = this.listOrigin
      this.localListName = this.listName
      this.localEscape = this.listEscape
    } else {
      this.localListOrigin = store.state.surveyStruct.surveyId
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('item-index', itemIndex)
    },
    moveItem(e, toIndex) {
      const fromIndex = e.dataTransfer.getData('item-index')
      const itemToMove = this.localListItemsArray.splice(fromIndex, 1)[0]
      this.localListItemsArray.splice(toIndex, 0, itemToMove)
    },
    removeItem(item, index) {
      if (this.localEscape === item) {
        this.localEscape = null
      }
      this.localListItemsArray.splice(index, 1)
    },
    addItem(e) {
      this.localListItemsArray.push(e.target.value)
      e.target.value = ''
    },
    updateItem(e, index) {
      this.localListItemsArray.splice(index, 1, e.target.value)
    },
    sortList() {
      this.localListItemsArray.sort((a, b) => {
        return a.localeCompare(b)
      })
    },
    generateList() {
      this.inviteList = []
      let inviteArray = this.inviteTextarea.split(/\r?\n/)
      this.localListItemsArray = this.localListItemsArray.concat(inviteArray)
      this.inviteTextarea = ''
    },
    saveList() {
      store.dispatch('postSurveyGlobalAndAnswerLists', {
        listName: this.localListName,
        listArray: this.localListItemsArray,
        origin: this.localListOrigin === 'g/' ? 'g' : this.surveyID,
        action: this.action
      })
      this.$emit('save-update', this.localListOrigin, this.localListName, this.localListItemsArray, this.localEscape)
      this.localEscape = null
    },
  },
}
</script>

<style scoped>
.item {
  background-color: lightgray;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}
.input-item {
  background-color: transparent;
  padding: 0.5rem;
  width: 100%;
  border: none;
}
</style>
