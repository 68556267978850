<template>
  <div @keydown.esc="$emit('close-update')">
    <form-wizard
      step-size="xs"
      shape="circle"
      title="Criação de Galeria de Vídeos"
      subtitle="Siga os passos abaixo para criar sua lista"
      color="rgba(var(--vs-primary), 1)"
      error-color="#e74c3c"
      @on-complete="saveList"
      @on-loading="setLoading"
      @on-error="handleErrorMessage"
    >
      <div
        v-if="loadingWizard"
        class="loader"
      />

      <!-- Tab 1 : Seleção -->
      <tab-content
        title="Seleção de arquivos"
        :before-change="checkTab1"
        icon="ti-upload"
      >
        <vs-card>
          <h3 class="card-title d-flex">
            Upload de arquivos
          </h3>
          <p class="card-subtitle">
            Faça o upload dos arquivos que deverão ser exibidos na pesquisa e clique em "próximo".
          </p>
          <div v-if="wizardErrorMsg">
            <vs-alert
              title="Por favor, verifique:"
              active="true"
              color="rgb(255, 130, 0)"
            >
              {{ wizardErrorMsg }}
            </vs-alert>
          </div>

          <!-- Lista de arquivos -->
          <div class="mb-3">
            <div
              v-if="localListItemsArray.length"
              class="con-upload w-100"
            >
              <div class="con-img-upload">
                <div
                  v-for="(item, index) in localListItemsArray"
                  :key="index"
                  class="img-upload"
                >
                  <div
                    class="item d-flex con-input-upload c-card-final"
                    style="margin: 0px"
                  >
                    <button
                      type="button"
                      class="btn-x-file"
                      @click="removeItem(item, index)"
                    >
                      <i
                        translate="translate"
                        class="material-icons notranslate"
                      > clear </i>
                    </button>
                    <button
                      class="btn-upload-file on-progress ready-progress"
                      style="height: 100%;"
                    >
                      <i
                        translate="translate"
                        class="material-icons notranslate"
                      > cloud_done </i><span>100 %</span>
                    </button>
                    <img
                      :style="{maxWidth: '100%', objectFit: 'cover', aspectRatio: '16/9'}"
                      :src="`http://img.youtube.com/vi/${item.linkId}/0.jpg`"
                      :alt="item.text"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="mt-3">
                <input
                  ref="file"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  @change="uploadFile"
                >
              </div>
              <!-- Input de URLs -->
              <vs-input
                class="w-50 my-3"
                label="Cole uma URL do Youtube abaixo e pressione a tecla Enter:"
                @keyup.enter="addItem($event)"
              />
            </div>
          </div>
        </vs-card>
      </tab-content>

      <!-- Tab 2 : Edição das informações -->
      <tab-content
        title="Edição das informações"
        :before-change="checkTab2"
        icon="ti-settings"
      >
        <vs-card>
          <h3 class="card-title">
            Edite a lista e seus itens:
          </h3>
          <!--          <div class="d-flex col-sm-12 col-md-12">-->
          <!--            <label class="pr-2" for="swImageZoom">Zoom ao clicar na imagem</label>-->
          <!--            <vs-switch name="swImageZoom" color="success" v-model="ilustrationConfig.zoom" />-->
          <!--          </div>-->

          <div v-if="wizardErrorMsg">
            <vs-alert
              title="Por favor, verifique:"
              active="true"
              color="rgb(255, 130, 0)"
            >
              {{ wizardErrorMsg }}
            </vs-alert>
          </div>

          <label>Renomeie e ordene os itens carregados:</label>
          <div class="con-upload mt-n2">
            <div
              v-for="(item, index) in localListItemsArray"
              :key="index"
              class="con-img-upload"
              style="cursor: grab"
              draggable
              @dragstart="pickupItem($event, index)"
              @drop="moveItem($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              <div class="item d-flex con-input-upload">
                <p
                  type="text"
                  class="input-item"
                >
                  {{ item.text }}
                </p>
                <img
                  :style="{maxWidth: '100%', objectFit: 'cover', aspectRatio: '16/9'}"
                  :src="`http://img.youtube.com/vi/${item.linkId}/0.jpg`"
                  :alt="item.text"
                >
              </div>
              <div class="col-sm-12 col-md-12 col mt-3">
                <label>Nome:</label>
                <input
                  v-model="item.text"
                  type="text"
                  class="ml-2 mb-2 px-2 c-input"
                  @keyup="updateItem($event, index)"
                >
                <br>
                <label for="format">Formato do vídeo:</label>
                <select
                  id="format"
                  v-model="item.format"
                  autocomplete="on"
                  tabindex="0"
                  name="format"
                  class="ml-2 mb-2 px-2 c-input"
                  disabled
                >
                  <option value="youtube">
                    Youtube
                  </option>
                  <option value="file">
                    Arquivo
                  </option>
                  <option
                    disabled
                    value="Outro"
                  >
                    Outro
                  </option>
                </select>
                <br>
                <label>Link ID:</label>
                <input
                  v-model="item.linkId"
                  type="text"
                  class="ml-2 mb-2 px-2 c-input"
                  disabled
                >
                <br>
              </div>
              <div
                v-if="item.format === 'youtube'"
                class="col-md-12"
              >
                <label>Início (seg):</label>
                <input
                  v-model.number="item.start"
                  type="number"
                  min="0"
                  class="ml-2 mb-2 px-2 c-input"
                >
                <label class="ml-3">Fim (seg):</label>
                <input
                  v-model.number="item.end"
                  type="number"
                  :min="item.start"
                  class="ml-2 px-2 c-input"
                >
              </div>
            </div>
          </div>
        </vs-card>
      </tab-content>

      <!-- Tab 3 : Revisão final -->
      <tab-content
        title="Revisão final"
        :before-change="checkTab3"
        icon="ti-check"
      >
        <vs-card>
          <h3 class="card-title">
            Revise sua lista
          </h3>
          <p class="card-subtitle">
            Verifique se está tudo certo e finalize sua nova lista.
          </p>
          <div
            v-for="(opt, index) in localListItemsArray"
            :key="index"
            class="p-2 d-flex justify-content-center"
          >
            <div
              class="w-100 img-btn"
              style="background-color: #fff;"
            >
              <h4 class="text-center pt-3 mb-4">
                {{ opt.text }}
              </h4>
              <div style="text-align: center; position: relative">
                <div
                  v-if="opt.format === 'youtube'"
                  style="background-position: center center; background-size: cover; aspect-ratio: 16 / 9; max-width: 640px; margin: 0 auto"
                  :style="{backgroundImage: `url('http://img.youtube.com/vi/${opt.linkId}/0.jpg')`}"
                >
                  <div
                    class="h-100 w-100"
                    style="background-color: rgba(0,0,0,.6)"
                  >
                    <youtube
                      :key="opt.reloadKey"
                      style="height: 100%; position: relative; z-index: 30"
                      :video-id="opt.linkId"
                      player-width="100%"
                      player-height="100%"
                      :player-vars="{controls: 0, playsinline: 0, rel: 0, start: opt.start, end: opt.end}"
                      @ended="endedVideo(index)"
                    />
                  </div>
                </div>
                <div
                  v-else
                  style="aspect-ratio: 16 / 9; max-width: 640px; margin: 0 auto"
                >
                  <div
                    class="h-100 w-100"
                    style="background-color: rgba(0,0,0,.6)"
                  >
                    <video
                      width="100%"
                      height="100%"
                      controls
                      style="position: relative; z-index: 30"
                    >
                      <source
                        :src="opt.ilustration"
                        type="video/mp4"
                      >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-card>
      </tab-content>


      <!-- wizard nav buttons -->
      <vs-button
        slot="prev"
        style="width: 100px"
        color="primary"
      >
        Voltar
      </vs-button>
      <vs-button
        slot="next"
        style="width: 100px"
        color="primary"
      >
        Avançar
      </vs-button>
      <vs-button
        slot="finish"
        style="width: 100px"
        color="success"
      >
        Concluir
      </vs-button>
    </form-wizard>
  </div>
</template>

<script>
// Import store because 'this.$store...' didn't work on this component (see the commit message for more details)
import store from '@/store/store.js'
import axios from 'axios'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'UpdateVideoGalleryList',
  components: { FormWizard, TabContent },
  props: {
    action: String,
    listItemsArray: Array,
  },
  data: () => ({
    showOptions: false,
    localListItemsArray: [],

    loadingWizard: false,
    wizardErrorMsg: null,
    wizardCount: 0,
  }),
  computed: {
    surveyID() {
      return store.state.surveyStruct.surveyId
    },
  },
  created() {
    // if is editing, clone the question object
    if (this.action === 'edit') {
      this.localListItemsArray = [...this.listItemsArray]

      this.localListItemsArray.forEach(el => {
        let array = el.ilustration.split('&')
        array.forEach(elItem => {
          el[elItem.split('=')[0]] = elItem.split('=')[1]
        })
        if (el.format === 'youtube') {
          el.ilustration = `http://img.youtube.com/vi/${el.linkId}/0.jpg`
        } else if (el.format === 'file') {
          el.ilustration = el.linkId
        }
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementsByTagName('input')[0].focus()
    })
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value
    },
    handleErrorMessage: function(errorMsg){
      this.wizardErrorMsg = errorMsg
    },
    isYoutubeVideo(value) {
      return value.includes('youtu')
    },
    isParamsAlreadySetOnElement(el) {
      return el.linkId
    },
    setYoutubeParamsToLocalItem(el, index) {
      let obj = {}
      obj.value = `${index}`
      obj.format = 'youtube'
      obj.start = 0
      obj.end = 0
      obj.reloadKey = false
      if (el.ilustration.includes('youtu.be')) {
        obj.linkId = `${el.ilustration.split('youtu.be/')[1].split('/')[0]}`
      } else if (el.ilustration.includes('?v=')) {
        obj.linkId = `${el.ilustration.split('?v=')[1].split('/')[0]}`
      }
      this.localListItemsArray[index] = Object.assign(this.localListItemsArray[index], obj)
    },
    setUploadedFileParamsToLocalItem(el, index) {
      let obj = {}
      obj.value = `${index}`
      obj.format = 'file'
      obj.start = 0
      obj.end = 0
      obj.reloadKey = false
      obj.linkId = el.ilustration
      this.localListItemsArray[index] = Object.assign(this.localListItemsArray[index], obj)
    },
    createParamsIllustrationArray(video) {
      let array = []
      array.push(`linkId=${video.linkId}`)
      array.push(`format=${video.format}`)
      array.push(`start=${video.start}`)
      array.push(`end=${video.end}`)
      video.ilustrationArray = array
    },
    joinIllustrationParams(el) {
      delete el.reloadKey
      delete el.linkId
      delete el.start
      delete el.end
      delete el.format
      el.ilustration = el.ilustrationArray.join('&')
      delete el.ilustrationArray
    },
    checkTab1() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            resolve(true)
          }
        }, 500)
      })
    },
    checkTab2() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            this.localListItemsArray.forEach((el, index) => {
              if (this.isYoutubeVideo(el.ilustration)) {
                // reload video Component
                el.reloadKey = !el.reloadKey
              }
              this.createParamsIllustrationArray(el, index)
            })
            resolve(true)
          }
        }, 500)
      })
    },
    checkTab3() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if(!this.localListItemsArray.length){
            reject('Não há itens à sua galeria.')
          }else if(this.localListItemsArray.length === 1){
            reject('A galeria deve ser composta por no mínimo dois itens.')
          }else{
            this.localListItemsArray.forEach((el) => {
              this.joinIllustrationParams(el)
            })
            resolve(true)
          }
        }, 500)
      })
    },
    endedVideo(index) {
      this.localListItemsArray[index].reloadKey = !this.localListItemsArray[index].reloadKey
      let obj = JSON.parse(JSON.stringify(this.localListItemsArray[index]))
      this.localListItemsArray.splice(index, 1, obj);
    },
    uploadFile() {
      this.Videos = this.$refs.file.files[0];
      this.submitFile()
    },
    submitFile() {
      const formData = new FormData();
      formData.append('file', this.Videos);
      const headers = { 'Content-Type': 'multipart/form-data' };
      axios.post(`https://dashapi.fanbase.com.br/api/Files/${store.state.ownerID}/3`, formData, { headers }).then((res) => {
        let name
        let src
        if (res.data.header.codigo === 1) {
          name = this.$refs.file.files[0].name ? this.$refs.file.files[0].name.split('.')[0] : 'Uploaded image'
          src = res.data.data.url ? res.data.data.url : 'Src'
          this.$vs.notify({
            color: 'success',
            title: 'Sucesso de upload',
            text: 'Carregamento de arquivos realizado.'
          })
          this.localListItemsArray.push({
            text: name,
            ilustration: src
          })
          this.setParamsToLocalItems()
        } else if (res.data.header.codigo === 0 && res.data.header.msg.startsWith('Arquivo já existente')) {
          name = this.$refs.file.files[0].name ? this.$refs.file.files[0].name.split('.')[0] : 'Uploaded image'
          src = `https://config.fanbase.com.br/user/fanbase/${this.$store.state.ownerID}/files/${this.$refs.file.files[0].name}`
          this.$vs.notify({
            color: 'success',
            title: 'Arquivo encontrado',
            text: `${res.data.header.msg}`
          })
          this.localListItemsArray.push({
            text: name,
            ilustration: src
          })
          this.setParamsToLocalItems()
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'Erro de upload',
            text: `${res.data.header.msg}`
          })
          this.$refs.file.files[0] = 0
        }
      });
    },
    addItem(e) {
      this.localListItemsArray.push({
        text: 'Inserted link',
        ilustration: e.target.value
      })
      this.setParamsToLocalItems()
      e.target.value = ''
    },
    setParamsToLocalItems() {
      this.localListItemsArray.forEach((el, index) => {
        if (this.isYoutubeVideo(el.ilustration) && !this.isParamsAlreadySetOnElement(el)) {
          this.setYoutubeParamsToLocalItem(el, index)
        } else if (!this.isYoutubeVideo(el.ilustration) && !this.isParamsAlreadySetOnElement(el)){
          this.setUploadedFileParamsToLocalItem(el, index)
        }
      })
    },
    pickupItem(e, itemIndex) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('item-index', itemIndex)
    },
    moveItem(e, toIndex) {
      const fromIndex = e.dataTransfer.getData('item-index')
      const itemToMove = this.localListItemsArray.splice(fromIndex, 1)[0]
      this.localListItemsArray.splice(toIndex, 0, itemToMove)
    },
    removeItem(item, index) {
      this.localListItemsArray.splice(index, 1)
    },
    updateItem(e, index) {
      this.localListItemsArray[index].text = e.target.value
    },
    sortList() {
      this.localListItemsArray.sort((a, b) => {
        return a.text.localeCompare(b.text)
      })
    },
    saveList() {
      this.$emit('save-update', this.localListItemsArray)
    },
  },
}
</script>

<style scoped>
.input-item {
  background-color: transparent;
  text-align: center;
  padding: 3px 0.5rem;
  width: 200px;
  height: auto;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.1);
  position: absolute;
  top: 5px;
  opacity: 1;
  cursor: text;
}

.input-url {
  width: 600px;
  text-overflow: ellipsis;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 8px;
  padding: 3px;
}

.c-card-final {
  border: 1px solid rgba(0,0,0,.1);
  background-color: rgba(var(--vs-success),.15);
}

.c-upload >>> .con-input-upload {
  height: 100px;
}
.c-upload {
  width: auto
}
.c-input {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
  padding: 0.4rem;
}
</style>
