<template>
  <div
    class="m-3"
    @keydown.esc="$emit('close-update')"
  >
    <!-- <div slot="header">
       <label for="" class="font-weight-bold">CONFIGURAR REQUISITOS</label>
       <i class="ml-2 mdi mdi-content-paste"></i>
       </div> -->
    <div class="row">
      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="m-2 col-sm-12 col-md-4 col"
      >
        <div class="ml-2">
          <label
            for="txtId"
            class="font-weight-bold"
          >Código</label>
          <vs-input
            id="txtId"
            v-model="requirLocal.code"
            name="txtId"
            style="width:100%"
            :disabled="isAutoCode"
          />
        </div>
      </div>
      <!--      <div class="d-flex pl-2">-->
      <!--        <label class="pr-2 align-self-center font-weight-bold" for="">Codigo automático</label>-->
      <!--        <vs-switch color="success"-->
      <!--                   class="pr-2 align-self-center"-->
      <!--                   v-model="isAutoCode"-->
      <!--                   disabled-->
      <!--                   @change="toggleGenerateCode"-->
      <!--        />-->
      <!--      </div>-->
      <div class="m-2 col-sm-12 col-md-12 col mt-1">
        <div class="">
          <label
            for="txtTexto"
            class="font-weight-bold ml-2"
          >Nome</label>
          <vs-input
            id="txtTexto"
            v-model="requirLocal.text"
            type="text"
            class="border-0 form-control"
            style="width:100%"
            @keydown.enter="saveRequirement"
          />
        </div>
      </div>
      <br>
      <div
        v-if="checkItemAccess(['Editar Projetos Digitais'])"
        class="m-2 col-sm-12 col-md-12 col "
      >
        <div class="">
          <label
            for="txtDescricao"
            class="font-weight-bold ml-2"
          >Descrição</label>
          <vs-input
            id="txtDescricao"
            v-model="requirLocal.description"
            type="text"
            class="border-0 form-control"
            style="width:100%"
          />
        </div>
      </div>
      <br>
    </div>
    <!-- <vs-collapse-item class="height-size"> -->
    <div
      slot="header"
      class="row mt-3 ml-1"
    >
      <div class="">
        <label class="font-weight-bold">CONFIG</label>
        <!--        <vs-input-->
        <!--          class="ml-3 col-10"-->
        <!--          v-model="requirLocal.config"-->
        <!--          disabled-->
        <!--        />-->
        <p
          class="ml-1 text-black-50"
          disabled
        >
          {{ requirLocal.config }}
        </p>
      </div>
      <!-- <i class="fa fa-edit ml-2" aria-hidden="true"></i> -->
    </div>
    <div>
      <ul class="mt-1 con-s group-buttons col-sm-12 col-md-12 col">
        <li class="ml-2 col-sm-3 col-md-12 d-flex">
          <div class="col-sm-12 col-md-4 d-flex">
            <label
              class="pr-2 mt-2 align-self-center"
              for=""
            >Botão de prosseguir: </label>
            <vs-switch
              v-model="swBtnOk"
              color="success"
              class="align-self-center"
              @input="swBtnOkChange"
            />
          </div>
          <div
            v-if="swBtnOk"
            class="ml-1 col-md-7 d-flex"
          >
            <vs-input
              v-model="swBtnOkText"
              class="col-md-12"
              style=""
              @change="swBtnInput"
            />
          </div>
        </li>

        <!-- goToOptions -->
        <GoToComponent
          v-if="localQuestions.length"
          v-model="goToConfig"
          class="m-2"
          :questions-list="localQuestions"
        />
      </ul>
      <div>
        <div
          v-if="checkItemAccess(['Editar Projetos Digitais'])"
          class="d-flex m-2 col-sm-3 col-md-3"
        >
          <div class="d-flex col-sm-12 col-md-12">
            <label
              class="pr-2"
              for="swskip"
            >Pular se</label>
            <vs-switch
              id="swskip"
              v-model="swskip"
              color="success"
              @change="toggleSkip"
            />
          </div>
        </div>
        <div
          v-if="swskip"
          class="col-sm-12 col-md-12 mb-3 groupSkip pt-4 pb-4 pr-2 pl-2"
        >
          <!--        <div class="col-sm-12 col-md-12 d-flex justify-content-end">-->
          <!--          <vs-button radius-->
          <!--                     color="transparent"-->
          <!--                     class="text-dark"-->
          <!--                     title="Lista de Condições">-->
          <!--            <i class="fa fa-list ml-2"-->
          <!--               id="btnListQuestionSkip"-->
          <!--               aria-hidden="true"></i>-->
          <!--          </vs-button>-->
          <!--        </div>-->
          <div
            v-if="swskip"
            class="row d-flex"
          >
            <div class="col-sm-12 col-md-9 ml-3">
              <label for="Pergunta">Pergunta</label>
              <select
                id="Pergunta"
                v-model="skipSelectQuestion"
                autocomplete="on"
                name="Pergunta"
                class="c-input"
                @change="skipUpdateAnswers"
              >
                <option
                  v-for="question in questionsList"
                  :key="question.id"
                  :value="question.id"
                >
                  {{ question.id + ': ' + question.text }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-9 ml-3">
              <label
                for="Operador"
                class="mt-3"
              >Operador</label>
              <select
                id="Operador"
                v-model="skipSelectOperator"
                name="Operador"
                class="c-input"
                @change="skipAnswer = []"
              >
                <option
                  v-for="(operator,index) in skipOperatorList"
                  :key="index"
                  :value="operator.value"
                >
                  {{ operator.text }}
                </option>
              </select>
            </div>
            <!--          <div class="col-sm-12 col-md-2 ml-2 d-flex">-->
            <!--            <vs-switch color="success"-->
            <!--                       v-model="skipCondition"-->
            <!--                       class="mt-3 align-self-center">-->
            <!--              <span slot="on">==</span>-->
            <!--              <span slot="off" color="danger">#</span>-->
            <!--            </vs-switch>-->
            <!--          </div>-->
          </div>
          <div
            v-if="skipAnswerList"
            class="row"
          >
            <div class="col-sm-12 col-md-9">
              <div class="row ml-3">
                <label
                  for="Resposta"
                  class="mt-3"
                >Resposta</label>
                <select
                  id="Resposta"
                  v-model="skipAnswer"
                  autocomplete="on"
                  :multiple="skipAnswerMultiple"
                  :style="[ skipAnswerMultiple ? { height: '4rem', overflow: 'auto'} : {}] "
                  class="col-sm-12 c-input"
                  name="Resposta"
                >
                  <option
                    v-for="(option, index) in skipAnswerList"
                    :key="index"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="row mt-3 ml-3">
                <div class="col-sm-12 col-md-4 d-flex ml-1">
                  <vs-button
                    color="success"
                    class="align-self-end w-100"
                    @click="saveQuestionInListSkip()"
                  >
                    Adicionar Condição
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="listSkip.length"
          class="col-sm-12 col-md-12 mb-3 groupSkip pt-2 pb-4 pr-2 pl-2"
        >
          <div class="col-sm-12 col-md-12 d-flex justify-content-end">
            <!--          <vs-button radius-->
            <!--                     color="transparent"-->
            <!--                     class="text-dark"-->
            <!--                     title="Adicionar Condições"-->
            <!--                     @click="addCondition">-->
            <!--            <i class="fa fa-plus ml-2"-->
            <!--               id="btnAddQuestionSkip"-->
            <!--               aria-hidden="true"></i>-->
            <!--          </vs-button>-->
          </div>
          <vs-table
            stripe
            :data="listSkip"
          >
            <template slot="thead">
              <vs-th>
                Condições
              </vs-th>
              <vs-th />
            </template>
            <template slot-scope="{ data }">
              <vs-tr
                v-for="(tr, indextr) in data"
                :key="indextr"
                :data="tr"
              >
                <!--              <vs-td>-->
                <!--                <vs-checkbox class="pt-3"-->
                <!--                             color="success"-->
                <!--                             v-model="skipCheck"-->
                <!--                             @change="skipCheckConditions(indextr)"-->

                <!--                />-->
                <!--              </vs-td>-->
                <vs-td :data="tr.descr">
                  {{ tr.descr }}
                </vs-td>
                <vs-td>
                  <vs-button
                    radius
                    color="transparent"
                    class="text-dark"
                    title="Excluir Condição"
                    @click="skipRemoveConditionItem(indextr)"
                  >
                    <i
                      id="btnRemoveCondition"
                      class="fa fa-trash ml-2"
                      aria-hidden="true"
                    />
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
    {{ goToConfig }}
    <!-- </vs-collapse-item> -->
    <vs-button
      color="success"
      class=" w-100 col-sm-12 col-md-12"
      :disabled="!requirLocal.text"
      @click="saveRequirement"
    >
      {{ !requirLocal.text ? 'Insira um texto ao requisito' : 'Salvar' }}
    </vs-button>
  </div>
</template>

<script>
import GoToComponent from '@/views/dashboards/dashboard-components/survey-structure/UpdateRequirComponents/GoToComponent.vue';

/* Services */
import Survey from '@/services/survey';

const ss = new Survey();

export default {
  name: 'UpdateRequir',
  components: {
    GoToComponent
  },
  props: {
    indexChap: Number,
    indexRequir: Number,
    action: String,
  },
  data: () => ({
    isAutoCode: true,
    idSkip: 0,
    //new
    requirLocal: {},
    requirConfigArray: [],
    indexRequirLocal: null,
    swBtnOk: false,
    swBtnOkText: '',

    swskip: false,
    questionsList: [],
    skipSelectQuestion: 0,
    skipSelectOperator: '=',
    skipOperatorList: [
      {text: '= (igual)', value: '='},
      {text: '! (diferente)', value: '!'},
      {text: '!& (diferente a conjunção de perguntas)', value: '!&'},
      {text: '=| (igual a disjunção de perguntas)', value: '=|'},
    ],
    listSkip: [],
    // skipCheck: false,
    // skipCondition: true,
    skipAnswer: [],
    skipAnswerList: [],

    selectedListOrigin: '',
    selectedList: '',

    goToConfig: '',
  }),
  computed: {
    skipAnswerJoined() {
      if (this.skipSelectOperator === '!' || this.skipSelectOperator === '=') {
        return this.skipAnswer
      } else {
        return this.skipAnswer.join('/#')
      }
    },
    skipAnswerMultiple() {
      if (this.skipSelectOperator === '=|' || this.skipSelectOperator === '!&') {
        return true
      } else
        return false
    },
    localQuestions() {
      return JSON.parse(JSON.stringify(this.requirLocal.questions))
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  created() {
    this.indexRequirLocal = this.indexRequir // just to avoid a prop being mutated
    // check if is editing
    if (this.action === 'edit') {
      // clone the object or create a fresh new one
      this.requirLocal = JSON.parse(JSON.stringify(this.$store.state.surveyStruct.chapters[this.indexChap].requirements[this.indexRequirLocal]))

      // set the config value to an array for best handling (separate 'btnOk' and 'skip' configs)
      if (this.requirLocal.config) {
        this.requirConfigArray = this.requirLocal.config.split(';')
        // set the btnOk-config text
        if (this.requirConfigArray.some(e => e.startsWith('btnok'))) {
          this.swBtnOkText = this.requirConfigArray.filter(e => e.startsWith('btnok'))[0].split(':')[1]
          this.swBtnOk = true
        }
        // if there are any skip config, add to skipConfig and to listSkip
        if (this.requirConfigArray.some(e => e.startsWith('skip'))) {
          // switch the swskip and check the checkBox item
          this.swskip = true
          let skipConfigListConfig = []
          // check if there is multiple questions
          if (this.requirConfigArray.some(e => e.startsWith('skip,'))) {
            skipConfigListConfig = this.requirConfigArray.filter(e => e.startsWith('skip'))[0].split(',')
            skipConfigListConfig.shift()
            this.addToListSkip(skipConfigListConfig)
          } else if (this.requirConfigArray.some(e => e.startsWith('skip--'))) {
            let skipMultipleQuestions = []
            skipMultipleQuestions = this.requirConfigArray.filter(e => e.startsWith('skip'))[0].split('--')
            //remove the first item 'skip'
            skipMultipleQuestions.shift()
            //add each array element to the listSkip array
            skipMultipleQuestions.forEach(item => {
              skipConfigListConfig = item.split(',')
              this.addToListSkip(skipConfigListConfig)
            })
          }
        }
        // set the goToConfig
        if (this.requirConfigArray.some(e => e.startsWith('goTo:'))) {
          this.goToConfig = this.requirConfigArray.filter(e => e.startsWith('goTo:'))[0];
        }
      }
    // check for adding a requirement
    } else if (this.action === 'add') {
      this.requirLocal = this.createFreshRequirObject()
      this.indexRequirLocal = this.$store.state.surveyStruct.chapters[this.indexChap].requirements.length
    }

    this.getQuestionList()
  },
  mounted() {
    document.getElementById('txtTexto').focus()
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true
      else {
        let resp = false

        access.forEach((i) => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true
          }
        })

        return resp
      }
    },
    createFreshRequirObject() {
      const code = this.generateNewCode()
      const id = ((this.indexChap+1)*100) + parseInt(code.split('.')[1])

      return {
        id,
        code,
        text: '',
        'description': '',
        'obs': '',
        'mandatory': true,

        'fullFillment': 0,
        'score': 0,
        'adjustedScore': 0,
        'factor': 0,
        'questFactorSum': 9,
        'config': '',
        'questions': []
      }
    },
    generateNewCode() {
      let newCode = ''
      let newReqCode = 0
      let newChapCode = 0
      // get the last requirement code
      if (this.$store.state.surveyStruct.chapters[this.indexChap].requirements.length) {
        const requirLength = this.$store.state.surveyStruct.chapters[this.indexChap].requirements.length
        const lastReqCode = this.$store.state.surveyStruct.chapters[this.indexChap].requirements[requirLength-1].code.split('.')[1]
        // split because it's better add '1' than add '0.1' to decimals and a '0.01' to centesimals
        newReqCode = parseFloat(lastReqCode)+1
        newChapCode = this.indexChap+1
      } else {
        newReqCode = 1
        newChapCode = this.indexChap+1
      }
      // generate the new code:
      newCode = newChapCode + '.' + newReqCode

      return newCode
    },
    // // ***** Functionality disabled for this version
    // toggleGenerateCode() {
    //   let newCode = ''
    //   if (this.isAutoCode && this.action === 'add') {
    //     newCode = this.generateNewCode()
    //   } else if (this.isAutoCode && this.action === 'edit'){
    //     return this.requirLocal.code = this.$store.state.surveyStruct.chapters[this.indexChap].requirements[this.indexRequirLocal].code
    //   } else {
    //     return this.requirLocal.code
    //   }
    //
    //   return this.requirLocal.code = newCode
    // },
    swBtnInput() {
      if (this.swBtnOkText) {
        const btnValue =  'btnok:' + this.swBtnOkText
        const getIndex = this.requirConfigArray.findIndex(e => e.startsWith('btnok'))
        getIndex === -1 ? this.requirConfigArray.push(btnValue) : this.requirConfigArray.splice(getIndex, 1, btnValue)
      } else if (this.swBtnOkText === '') {
        const getIndex = this.requirConfigArray.findIndex(e => e.startsWith('btnok'))
        if (getIndex !== -1) this.requirConfigArray.splice(getIndex, 1)
        this.swBtnOk = false
      }
      this.requirLocal.config = this.requirConfigArray.join(';')
    },
    swBtnOkChange() {
      if (this.swBtnOk === false) {
        const getIndex = this.requirConfigArray.findIndex(e => e.startsWith('btnok'))
        if (getIndex !== -1) this.requirConfigArray.splice(getIndex, 1)
        this.requirLocal.config = this.requirConfigArray.join(';')
        this.swBtnOkText = ''
      }
    },
    getQuestionList() {
      for (var c = 0; c <= this.indexChap; c++) {
        if (c !== this.indexChap) {
          this.questionsList.push(...this.$store.state.surveyStruct.chapters[c].requirements.map(e => e.questions).flat())
        } else {
          for (var r = 0; r < this.indexRequirLocal; r++) {
            this.questionsList.push(...this.$store.state.surveyStruct.chapters[this.indexChap].requirements[r].questions)
          }
        }
      }
    },
    toggleSkip() {
      if (this.swskip === false) {
        // if toggle off
        // remove skip from requirConfigArray
        if (this.requirConfigArray.some(e => e.startsWith('skip'))) {
          const indexSkip = this.requirConfigArray.findIndex(e => e.startsWith('skip'))
          this.requirConfigArray.splice(indexSkip, 1)
        }
        // set the new requirLocal without skip config
        this.requirLocal.config = this.requirConfigArray.join(';')
        // erase listSkip
        this.listSkip = []
      }
    },
    skipUpdateAnswers() {
      // set from the config
      const selectQuestion = this.questionsList.find(e => e.id === this.skipSelectQuestion)
      if (selectQuestion.config.includes('optlist')) {
        const questionConfigArray = selectQuestion.config.split(';')
        const optlist = questionConfigArray.filter(e => e.startsWith('optlist'))[0].split(':')[1]
        if (optlist.startsWith('g/')) {
          this.selectedListOrigin = 'g/'
          this.selectedList = optlist.split('/')[1]
        } else {
          this.selectedListOrigin = this.$store.state.surveyStruct.surveyId + '/'
          this.selectedList = optlist
        }
        this.updateSkipAnswerList()
      // set from the type
      } else if (selectQuestion.type === 4) {
        this.skipAnswerList = ['Sim', 'Não']
      // set from the options
      } else if (selectQuestion.options.length) {
        this.skipAnswerList = selectQuestion.options.map(e => e.text)
      } else {
        this.skipAnswerList = []
      }
    },
    updateSkipAnswerList() {
      const urlItem = this.selectedListOrigin + this.selectedList;

      ss
        .getSurveyList(urlItem, this.token)
        .then(ret => {
          this.skipAnswerList = [...ret]
          // this.closeLoadingDiv()
        }).catch(err => {
          // this.closeLoadingDiv()
          this.skipAnswerList = []
          alert('Houve um problema no carregamento da lista de respostas. \r\nVerifique se o nome está correto ou contate o suporte\r\n \r\n' + err)
        })
    },
    addToListSkip(skipConfig) {
      this.idSkip++
      let skipConfiguration = {
        id: this.idSkip,
        skipQuestion: skipConfig[0],
        skipSelectOperator: skipConfig[1],
        skipAnswer: skipConfig[2],
        descr: `Pula esta pergunta se a resposta da pergunta "${skipConfig[0]}" for "${skipConfig[1]}" a "${skipConfig[2]}"`,
      };
      this.listSkip.push(skipConfiguration);
    },
    addQuestionInRequirConfig(skipConfig) {
      // add on requirLocal.config
      let skipValue = ''
      if (this.listSkip.length === 0) {
        // set skipValue
        skipValue = 'skip,' + skipConfig[0] + ',' + skipConfig[1] + ',' + skipConfig[2]
        this.requirConfigArray.push(skipValue)
      } else if (this.listSkip.length > 0) {
        // get current Skip config Index
        const skipIndex = this.requirConfigArray.findIndex(e => e.startsWith('skip'))
        let currentSkipConfig = []
        // set skipValue
        if (this.listSkip.length === 1) {
          currentSkipConfig = this.requirConfigArray[skipIndex].split(',')
          currentSkipConfig.shift()
          skipValue = 'skip'
          skipValue = skipValue.concat('--' + currentSkipConfig[0] + ',' + currentSkipConfig[1] + ',' + currentSkipConfig[2])
          skipValue = skipValue.concat('--' + skipConfig[0] + ',' + skipConfig[1] + ',' + skipConfig[2])
        } else if (this.listSkip.length > 1) {
          currentSkipConfig = this.requirConfigArray[skipIndex].split('--')
          currentSkipConfig.shift()
          skipValue = 'skip'
          currentSkipConfig.forEach(item => {
            const skipConfigItem = item.split(',')
            skipValue = skipValue.concat('--' + skipConfigItem[0] + ',' + skipConfigItem[1] + ',' + skipConfigItem[2])
          })
          skipValue = skipValue.concat('--' + skipConfig[0] + ',' + skipConfig[1] + ',' + skipConfig[2])
        }
        this.requirConfigArray.splice(skipIndex, 1, skipValue)
      }
      this.requirLocal.config = this.requirConfigArray.join(';')
    },
    saveQuestionInListSkip() {
      // add on listSkip
      let skipConfig = []
      skipConfig.push(this.skipSelectQuestion)
      skipConfig.push(this.skipSelectOperator)
      skipConfig.push(this.skipAnswerJoined)
      this.addQuestionInRequirConfig(skipConfig)
      this.addToListSkip(skipConfig);
    },
    skipRemoveConditionItem(index) {
      // remove item from skip and store it
      let itemToRemove = this.listSkip.splice(index, 1)[0]
      itemToRemove = itemToRemove.skipQuestion + ',' + itemToRemove.skipSelectOperator  + ',' + itemToRemove.skipAnswer
      // remove from requirConfigArray
      const getIndexSkip = this.requirConfigArray.findIndex(e => e.startsWith('skip'))
      if (this.listSkip.length === 0) {
        this.requirConfigArray.splice(getIndexSkip, 1)
      } else if (this.listSkip.length > 0) {
        let currentSkipConfig = this.requirConfigArray[getIndexSkip].split('--')
        const getIndexItem = currentSkipConfig.findIndex(item => item === itemToRemove)
        currentSkipConfig.splice(getIndexItem, 1)
        this.listSkip.length === 1
          ? currentSkipConfig = currentSkipConfig.join(',')
          : currentSkipConfig = currentSkipConfig.join('--')
        this.requirConfigArray.splice(getIndexSkip, 1, currentSkipConfig)
      }
      this.requirLocal.config = this.requirConfigArray.join(';')
    },
    // // this is the checkbox inside the listSkip
    // skipCheckConditions(index) {
    //   alert('the skipCheck: ' + this.skipCheck)
    //   if (this.skipCheck === true) {
    //     const skipValue = 'skip,' + this.listSkip[index].skipQuestion + ',' + this.listSkip[index].skipSelectOperator
    //         + ',' + this.listSkip[index].skipAnswer
    //     alert('teste ' + skipValue)
    // const getIndexSkip = this.requirConfigArray.findIndex(e => e.startsWith('skip'))
    // getIndexSkip === -1 ? this.requirConfigArray.push(skipValue) : this.requirConfigArray.splice(getIndexSkip, 1, skipValue)
    // this.requirLocal.config = this.requirConfigArray.join(';')
    //   }
    // },

    // // we aren't using this
    // addCondition() {
    //   this.skipCondition = true
    //   this.swskip = true;
    // },
    mountGoToConfig() {
      if (this.goToConfig) {
        const getIndex = this.requirConfigArray.findIndex(e => e.startsWith('goTo'))
        getIndex === -1 ? this.requirConfigArray.push(this.goToConfig) : this.requirConfigArray.splice(getIndex, 1, this.goToConfig)
      } else {
        const getIndex = this.requirConfigArray.findIndex(e => e.startsWith('goTo'))
        if (getIndex !== -1) this.requirConfigArray.splice(getIndex, 1)
      }

      this.requirLocal.config = this.requirConfigArray.join(';')
    },
    async saveRequirement() {
      this.mountGoToConfig()
      if (this.action === 'edit') {
        let payload = { indexChap: this.indexChap, indexRequir: this.indexRequirLocal, value: this.requirLocal }
        this.$store.dispatch('updateRequirement', payload)
      } else
      {
        await this.$store.dispatch('addRequirement', { indexChap: this.indexChap, value: this.requirLocal})
        await this.$store.dispatch('moveRequirement', {
          fromIndexRequir: this.indexRequirLocal,
          toIndexRequir: this.indexRequir+1,
          fromIndexChap: this.indexChap,
          toIndexChap: this.indexChap
        });
      }
      this.$store.dispatch('saveSurveyStruct').then(() => {
        this.$vs.notify({title: 'Salvo',text: 'Questionário salvo', color: 'success', icon: 'check_box'})
      }).catch((err) => {
        this.$vs.notify({title: 'Erro ao salvar', text: `Tente salvar manualmente: (${err})`, color: 'danger', icon: 'warning'})
      })
      return this.$emit('close-update')
    },
  },
}
</script>
<style scoped>
h3, h4 {
  font-weight: 300;
}

.group-buttons {
  list-style: none;
}

inputx {
  width: 100%;
}

button {
  outline: none !Important;
}

/* .vs-con-input-label {
  width: 100%;
} */

.groupSkip {
  border-radius: 4px;
  background-color: #36bea61f;
}

.c-input {
  width: 100%;
  height: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 7px;
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
}

</style>
